a.danger-btn:link, a.danger-btn:visited, a.danger-btn:active {
  color: #181c211a;
}

a.danger-btn:hover {
  color: #d9534f;
}

.table .card-table th, .table .card-table td {
  padding: .525em !important;
}

.hr-text {
  color: #000;
  text-align: center;
  height: 1.5em;
  opacity: .6;
  border: 0;
  outline: 0;
  font-size: .75rem;
  line-height: 1em;
  position: relative;
}

.hr-text:before {
  content: "";
  background: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(#181c2199), to(transparent) );
  width: 100%;
  height: 1px;
  background: linear-gradient(to right, #0000, #181c2199, #0000);
  position: absolute;
  top: 50%;
  left: 0;
}

.hr-text:after {
  content: attr(data-content);
  background-color: #fff;
  padding: 0 .5em;
  line-height: 1.5em;
  display: inline-block;
  position: relative;
}

#card-body {
  height: 100vh;
  overflow-y: auto;
}

div.media-body {
  font-size: .85rem !important;
  line-height: 1.25rem !important;
}

table.chit-table th:last-child, table.chit-table td:last-child, table.chit-table.edit th:last-child, table.chit-table.edit td:last-child {
  text-align: right;
}

table.chit-table.edit td:nth-child(1) {
  width: 60%;
}

table.chit-table.edit td:nth-child(2) {
  width: 30%;
}

table.chit-table.edit td:nth-child(3) {
  width: 10%;
}

table.chit-table.edit th:nth-child(2), table.chit-table.edit td:nth-child(2) {
  text-align: right;
}

.bg-lighter td {
  font-weight: bold !important;
}

tr.chit-totals {
  background-color: inherit;
  font-size: 110%;
  font-weight: bold;
}

.form-label.hide {
  display: none !important;
}

.opacity-25 {
  opacity: .25 !important;
}

.opacity-50 {
  opacity: .5 !important;
}

.opacity-75 {
  opacity: .75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.text-tiny {
  font-size: 70% !important;
}

.text-big {
  font-size: 112% !important;
}

.text-large {
  font-size: 150% !important;
}

.text-xlarge {
  font-size: 170% !important;
}

.text-break {
  overflow-wrap: break-word;
}

.line-height-1 {
  line-height: 1 !important;
}

.line-height-condenced {
  line-height: 1.3 !important;
}

.line-height-inherit {
  line-height: inherit !important;
}

.text-expanded {
  letter-spacing: .06991em !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-semibold {
  font-weight: 500 !important;
}

.font-weight-bolder {
  font-weight: 900 !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-transform-none {
  text-transform: none !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-auto {
  overflow: auto !important;
}

.box-shadow-none {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.container-p-x {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

@media (min-width: 992px) {
  .container-p-x {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
}

.container-m--x {
  margin-left: -1rem !important;
  margin-right: -1rem !important;
}

@media (min-width: 992px) {
  .container-m--x {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }
}

.container-p-y:not([class^="pt-"]):not([class*=" pt-"]) {
  padding-top: 1.5rem !important;
}

.container-p-y:not([class^="pb-"]):not([class*=" pb-"]) {
  padding-bottom: 1.5rem !important;
}

.container-m--y:not([class^="mt-"]):not([class*=" mt-"]) {
  margin-top: -1.5rem !important;
}

.container-m--y:not([class^="mb-"]):not([class*=" mb-"]) {
  margin-bottom: -1.5rem !important;
}

.rotate-90 {
  transform: rotate(90deg);
}

.rotate-180 {
  transform: rotate(180deg);
}

.rotate-270 {
  transform: rotate(270deg);
}

.rotate--90 {
  transform: rotate(-90deg);
}

.rotate--180 {
  transform: rotate(-180deg);
}

.rotate--270 {
  transform: rotate(-270deg);
}

.rotate-0 {
  transform: rotate(0deg) !important;
}

.scaleX--1 {
  transform: scaleX(-1);
}

.scaleY--1 {
  transform: scaleY(-1);
}

.row-bordered {
  overflow: hidden;
}

.row-bordered > .col, .row-bordered > [class^="col-"], .row-bordered > [class*=" col-"], .row-bordered > [class^="col "], .row-bordered > [class*=" col "], .row-bordered > [class$=" col"], .row-bordered > [class="col"] {
  padding-top: 1px;
  position: relative;
}

.row-bordered > .col:before, .row-bordered > [class^="col-"]:before, .row-bordered > [class*=" col-"]:before, .row-bordered > [class^="col "]:before, .row-bordered > [class*=" col "]:before, .row-bordered > [class$=" col"]:before, .row-bordered > [class="col"]:before {
  content: "";
  height: 0;
  border-top: 1px solid #181c210f;
  display: block;
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
}

.row-bordered > .col:after, .row-bordered > [class^="col-"]:after, .row-bordered > [class*=" col-"]:after, .row-bordered > [class^="col "]:after, .row-bordered > [class*=" col "]:after, .row-bordered > [class$=" col"]:after, .row-bordered > [class="col"]:after {
  content: "";
  width: 0;
  border-left: 1px solid #181c210f;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -1px;
}

.row-bordered.row-border-light > .col:before, .row-bordered.row-border-light > .col:after, .row-bordered.row-border-light > [class^="col-"]:before, .row-bordered.row-border-light > [class^="col-"]:after, .row-bordered.row-border-light > [class*=" col-"]:before, .row-bordered.row-border-light > [class*=" col-"]:after, .row-bordered.row-border-light > [class^="col "]:before, .row-bordered.row-border-light > [class^="col "]:after, .row-bordered.row-border-light > [class*=" col "]:before, .row-bordered.row-border-light > [class*=" col "]:after, .row-bordered.row-border-light > [class$=" col"]:before, .row-bordered.row-border-light > [class$=" col"]:after, .row-bordered.row-border-light > [class="col"]:before, .row-bordered.row-border-light > [class="col"]:after {
  border-color: #181c210f;
}

@media (min-width: 576px) {
  .w-sm-100 {
    width: 100% !important;
  }

  .w-sm-auto {
    width: auto !important;
  }
}

@media (min-width: 768px) {
  .w-md-100 {
    width: 100% !important;
  }

  .w-md-auto {
    width: auto !important;
  }
}

@media (min-width: 992px) {
  .w-lg-100 {
    width: 100% !important;
  }

  .w-lg-auto {
    width: auto !important;
  }
}

@media (min-width: 1200px) {
  .w-xl-100 {
    width: 100% !important;
  }

  .w-xl-auto {
    width: auto !important;
  }
}

.mr--0, .mx--0 {
  margin-right: 0 !important;
}

.ml--0, .mx--0 {
  margin-left: 0 !important;
}

.mr--1, .mx--1 {
  margin-right: -.25rem !important;
}

.ml--1, .mx--1 {
  margin-left: -.25rem !important;
}

.mr--2, .mx--2 {
  margin-right: -.5rem !important;
}

.ml--2, .mx--2 {
  margin-left: -.5rem !important;
}

.mr--3, .mx--3 {
  margin-right: -1rem !important;
}

.ml--3, .mx--3 {
  margin-left: -1rem !important;
}

.mr--4, .mx--4 {
  margin-right: -1.5rem !important;
}

.ml--4, .mx--4 {
  margin-left: -1.5rem !important;
}

.mr--5, .mx--5 {
  margin-right: -3rem !important;
}

.ml--5, .mx--5 {
  margin-left: -3rem !important;
}

@media (min-width: 576px) {
  .mr-sm--0, .mx-sm--0 {
    margin-right: 0 !important;
  }

  .ml-sm--0, .mx-sm--0 {
    margin-left: 0 !important;
  }

  .mr-sm--1, .mx-sm--1 {
    margin-right: -.25rem !important;
  }

  .ml-sm--1, .mx-sm--1 {
    margin-left: -.25rem !important;
  }

  .mr-sm--2, .mx-sm--2 {
    margin-right: -.5rem !important;
  }

  .ml-sm--2, .mx-sm--2 {
    margin-left: -.5rem !important;
  }

  .mr-sm--3, .mx-sm--3 {
    margin-right: -1rem !important;
  }

  .ml-sm--3, .mx-sm--3 {
    margin-left: -1rem !important;
  }

  .mr-sm--4, .mx-sm--4 {
    margin-right: -1.5rem !important;
  }

  .ml-sm--4, .mx-sm--4 {
    margin-left: -1.5rem !important;
  }

  .mr-sm--5, .mx-sm--5 {
    margin-right: -3rem !important;
  }

  .ml-sm--5, .mx-sm--5 {
    margin-left: -3rem !important;
  }
}

@media (min-width: 768px) {
  .mr-md--0, .mx-md--0 {
    margin-right: 0 !important;
  }

  .ml-md--0, .mx-md--0 {
    margin-left: 0 !important;
  }

  .mr-md--1, .mx-md--1 {
    margin-right: -.25rem !important;
  }

  .ml-md--1, .mx-md--1 {
    margin-left: -.25rem !important;
  }

  .mr-md--2, .mx-md--2 {
    margin-right: -.5rem !important;
  }

  .ml-md--2, .mx-md--2 {
    margin-left: -.5rem !important;
  }

  .mr-md--3, .mx-md--3 {
    margin-right: -1rem !important;
  }

  .ml-md--3, .mx-md--3 {
    margin-left: -1rem !important;
  }

  .mr-md--4, .mx-md--4 {
    margin-right: -1.5rem !important;
  }

  .ml-md--4, .mx-md--4 {
    margin-left: -1.5rem !important;
  }

  .mr-md--5, .mx-md--5 {
    margin-right: -3rem !important;
  }

  .ml-md--5, .mx-md--5 {
    margin-left: -3rem !important;
  }
}

@media (min-width: 992px) {
  .mr-lg--0, .mx-lg--0 {
    margin-right: 0 !important;
  }

  .ml-lg--0, .mx-lg--0 {
    margin-left: 0 !important;
  }

  .mr-lg--1, .mx-lg--1 {
    margin-right: -.25rem !important;
  }

  .ml-lg--1, .mx-lg--1 {
    margin-left: -.25rem !important;
  }

  .mr-lg--2, .mx-lg--2 {
    margin-right: -.5rem !important;
  }

  .ml-lg--2, .mx-lg--2 {
    margin-left: -.5rem !important;
  }

  .mr-lg--3, .mx-lg--3 {
    margin-right: -1rem !important;
  }

  .ml-lg--3, .mx-lg--3 {
    margin-left: -1rem !important;
  }

  .mr-lg--4, .mx-lg--4 {
    margin-right: -1.5rem !important;
  }

  .ml-lg--4, .mx-lg--4 {
    margin-left: -1.5rem !important;
  }

  .mr-lg--5, .mx-lg--5 {
    margin-right: -3rem !important;
  }

  .ml-lg--5, .mx-lg--5 {
    margin-left: -3rem !important;
  }
}

@media (min-width: 1200px) {
  .mr-xl--0, .mx-xl--0 {
    margin-right: 0 !important;
  }

  .ml-xl--0, .mx-xl--0 {
    margin-left: 0 !important;
  }

  .mr-xl--1, .mx-xl--1 {
    margin-right: -.25rem !important;
  }

  .ml-xl--1, .mx-xl--1 {
    margin-left: -.25rem !important;
  }

  .mr-xl--2, .mx-xl--2 {
    margin-right: -.5rem !important;
  }

  .ml-xl--2, .mx-xl--2 {
    margin-left: -.5rem !important;
  }

  .mr-xl--3, .mx-xl--3 {
    margin-right: -1rem !important;
  }

  .ml-xl--3, .mx-xl--3 {
    margin-left: -1rem !important;
  }

  .mr-xl--4, .mx-xl--4 {
    margin-right: -1.5rem !important;
  }

  .ml-xl--4, .mx-xl--4 {
    margin-left: -1.5rem !important;
  }

  .mr-xl--5, .mx-xl--5 {
    margin-right: -3rem !important;
  }

  .ml-xl--5, .mx-xl--5 {
    margin-left: -3rem !important;
  }
}

.flex-truncate {
  min-width: 0 !important;
}

.flex-basis-100 {
  flex-basis: 100% !important;
}

.flex-basis-auto {
  flex-basis: auto !important;
}

@media (min-width: 576px) {
  .flex-basis-sm-100 {
    flex-basis: 100% !important;
  }

  .flex-basis-sm-auto {
    flex-basis: auto !important;
  }
}

@media (min-width: 768px) {
  .flex-basis-md-100 {
    flex-basis: 100% !important;
  }

  .flex-basis-md-auto {
    flex-basis: auto !important;
  }
}

@media (min-width: 992px) {
  .flex-basis-lg-100 {
    flex-basis: 100% !important;
  }

  .flex-basis-lg-auto {
    flex-basis: auto !important;
  }
}

@media (min-width: 1200px) {
  .flex-basis-xl-100 {
    flex-basis: 100% !important;
  }

  .flex-basis-xl-auto {
    flex-basis: auto !important;
  }
}

.d-flex, .d-inline-flex, .media, .media > :not(.media-body), .jumbotron, .card {
  flex-shrink: 1;
}

.ie-mh-1 {
  min-height: 1px;
}

.text-dark {
  color: #4e5155 !important;
}

.text-dark[href]:hover {
  color: #03ee97 !important;
}

.text-dark[href]:focus:not(:hover) {
  color: #4e5155 !important;
}

.text-muted[href]:hover, .text-muted[href]:focus {
  color: #8c8e90 !important;
}

.text-light {
  color: #babbbc !important;
}

.text-light[href]:hover, .text-light[href]:focus {
  color: #8c8e90 !important;
}

.text-lighter {
  color: #d1d2d3 !important;
}

.text-lighter[href]:hover, .text-lighter[href]:focus {
  color: #8c8e90 !important;
}

.text-lightest {
  color: #e8e8e9 !important;
}

.text-lightest[href]:hover, .text-lightest[href]:focus {
  color: #8c8e90 !important;
}

.bg-dark, a.bg-dark:hover, a.bg-dark:focus {
  background-color: #181c21e6 !important;
}

.bg-light {
  background-color: #181c210f !important;
}

a.bg-light:hover, a.bg-light:focus {
  background-color: #181c211a !important;
}

.bg-lighter {
  background-color: #181c2108 !important;
}

a.bg-lighter:hover, a.bg-lighter:focus {
  background-color: #181c210f !important;
}

.bg-lightest {
  background-color: #181c2104 !important;
}

a.bg-lightest:hover, a.bg-lightest:focus {
  background-color: #181c2108 !important;
}

.border-light {
  border-color: #181c210f !important;
}

.border-dark {
  border-color: #181c21e6 !important;
}

.border-transparent {
  border-color: #0000 !important;
}

body {
  -moz-font-feature-settings: "liga" on;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
}

@media (min-width: 768px) {
  button.list-group-item {
    outline: none !important;
  }
}

@media (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .card, .card-body, .media, .flex-column, .tab-content {
    min-height: 1px;
  }

  img {
    min-height: 1px;
    height: auto;
  }
}

.container, .container-fluid {
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (min-width: 992px) {
  .container, .container-fluid {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.img-thumbnail {
  display: block;
  position: relative;
}

.img-thumbnail img {
  z-index: 1;
}

.img-thumbnail-content {
  z-index: 3;
  opacity: 0;
  transition: all .2s ease-in-out;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.img-thumbnail:hover .img-thumbnail-content, .img-thumbnail:focus .img-thumbnail-content {
  opacity: 1;
}

.img-thumbnail-overlay {
  z-index: 2;
  transition: all .2s ease-in-out;
  display: block;
  position: absolute;
  inset: 0;
}

.img-thumbnail:not(:hover):not(:focus) .img-thumbnail-overlay {
  opacity: 0 !important;
}

.img-thumbnail-shadow {
  transition: box-shadow .2s, -webkit-box-shadow .2s;
}

.img-thumbnail-shadow:hover, .img-thumbnail-shadow:focus {
  box-shadow: 0 5px 20px #181c2166;
}

.img-thumbnail-zoom-in {
  overflow: hidden;
}

.img-thumbnail-zoom-in img {
  -webkit-transform: translate3d(0);
  transform: translate3d(0);
  transition: all .3s ease-in-out;
}

.img-thumbnail-zoom-in .img-thumbnail-content {
  transform: translate(-50%, -50%)scale(.6);
}

.img-thumbnail-zoom-in:hover img, .img-thumbnail-zoom-in:focus img {
  transform: scale(1.1);
}

.img-thumbnail-zoom-in:hover .img-thumbnail-content, .img-thumbnail-zoom-in:focus .img-thumbnail-content {
  transform: translate(-50%, -50%)scale(1);
}

.table .table-secondary, .table .table-secondary > th, .table .table-secondary > td {
  color: #616263;
  background-color: #f3f5f7;
  border-color: #00000009;
}

.table-hover .table-secondary:hover, .table-hover .table-secondary:hover > td, .table-hover .table-secondary:hover > th {
  background-color: #f1f3f5;
}

.table .table-success, .table .table-success > th, .table .table-success > td {
  color: #5c6360;
  background-color: #e6f8f1;
  border-color: #00000009;
}

.table-hover .table-success:hover, .table-hover .table-success:hover > td, .table-hover .table-success:hover > th {
  background-color: #e4f6ef;
}

.table .table-info, .table .table-info > th, .table .table-info > td {
  color: #5e6464;
  background-color: #eaf9fb;
  border-color: #00000009;
}

.table-hover .table-info:hover, .table-hover .table-info:hover > td, .table-hover .table-info:hover > th {
  background-color: #e8f7f8;
}

.table .table-warning, .table .table-warning > th, .table .table-warning > td {
  color: #66645f;
  background-color: #fffbee;
  border-color: #00000009;
}

.table-hover .table-warning:hover, .table-hover .table-warning:hover > td, .table-hover .table-warning:hover > th {
  background-color: #fcf8ec;
}

.table .table-danger, .table .table-danger > th, .table .table-danger > td {
  color: #645f5f;
  background-color: #fbeeed;
  border-color: #00000009;
}

.table-hover .table-danger:hover, .table-hover .table-danger:hover > td, .table-hover .table-danger:hover > th {
  background-color: #f8eceb;
}

.table .table-dark, .table .table-dark > th, .table .table-dark > td {
  color: #5d5d5d;
  background-color: #e8e8e9;
  border-color: #00000009;
}

.table-hover .table-dark:hover, .table-hover .table-dark:hover > td, .table-hover .table-dark:hover > th {
  background-color: #e6e6e7;
}

.table.table-fixed {
  table-layout: fixed;
}

.table th {
  font-weight: 500;
}

.table-responsive > .table {
  max-width: none;
}

.table-active, .table-active > th, .table-active > td {
  background-color: #f7f7f7;
  border-color: #181c2109;
}

.table-hover .table-active:hover, .table-hover .table-active:hover > td, .table-hover .table-active:hover > th {
  background-color: #f5f5f5;
}

.card-table {
  margin-bottom: 0;
}

.card-table, .card-table th, .card-table td {
  border-color: #181c210f;
}

.card-table > thead:first-child > tr:first-child > td, .card-table > thead:first-child > tr:first-child > th, .card-table > tbody:first-child > tr:first-child > td, .card-table > tbody:first-child > tr:first-child > th, .card-table > tfoot:first-child > tr:first-child > td, .card-table > tfoot:first-child > tr:first-child > th {
  border-top: 0;
}

.card-table > thead > tr > td:first-child, .card-table > thead > tr > th:first-child, .card-table > tbody > tr > td:first-child, .card-table > tbody > tr > th:first-child, .card-table > tfoot > tr > td:first-child, .card-table > tfoot > tr > th:first-child {
  border-left: 0;
  padding-left: 1.5rem;
}

.card-table > thead > tr > td:last-child, .card-table > thead > tr > th:last-child, .card-table > tbody > tr > td:last-child, .card-table > tbody > tr > th:last-child, .card-table > tfoot > tr > td:last-child, .card-table > tfoot > tr > th:last-child {
  border-right: 0;
  padding-right: 1.5rem;
}

.card-condenced .card-table > thead > tr > td:first-child, .card-condenced .card-table > thead > tr > th:first-child, .card-condenced .card-table > tbody > tr > td:first-child, .card-condenced .card-table > tbody > tr > th:first-child, .card-condenced .card-table > tfoot > tr > td:first-child, .card-condenced .card-table > tfoot > tr > th:first-child {
  border-left: 0;
  padding-left: 1rem;
}

.card-condenced .card-table > thead > tr > td:last-child, .card-condenced .card-table > thead > tr > th:last-child, .card-condenced .card-table > tbody > tr > td:last-child, .card-condenced .card-table > tbody > tr > th:last-child, .card-condenced .card-table > tfoot > tr > td:last-child, .card-condenced .card-table > tfoot > tr > th:last-child {
  border-right: 0;
  padding-right: 1rem;
}

.btn {
  font-size: .894rem;
}

.btn .badge {
  transition: all .2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .btn .badge {
    transition: none;
  }
}

label.btn {
  margin-bottom: 0;
}

.btn-secondary {
  color: #fff;
  background: #8897aa;
  border-color: #0000;
}

.btn-secondary:hover {
  color: #fff;
  background: #818fa2;
  border-color: #0000;
}

.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: 0 0 0 2px #8897aa66;
}

.btn-secondary.disabled, .btn-secondary:disabled {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: #fff !important;
  background: #8897aa !important;
  border-color: #0000 !important;
}

.btn-secondary .badge {
  color: #8897aa;
  background: #fff;
}

.btn-secondary:active, .btn-secondary.active, .show > .btn-secondary.dropdown-toggle {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #768394;
  border-color: #0000;
}

.btn-group .btn-secondary, .input-group-prepend .btn-secondary, .input-group-append .btn-secondary {
  border-left: 1px solid #768394;
  border-right: 1px solid #768394;
}

.btn-outline-secondary {
  color: #8897aa;
  background: none;
  border-color: #8897aa;
}

.btn-outline-secondary:hover {
  color: #fff;
  background: #8897aa;
  border-color: #0000;
}

.btn-outline-secondary:hover .badge {
  color: #8897aa;
  background: #fff;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 2px #8897aa66;
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #8897aa !important;
  background: none !important;
  border-color: #8897aa !important;
}

.btn-outline-secondary .badge {
  color: #fff;
  background: #8897aa;
}

.btn-outline-secondary:active, .btn-outline-secondary.active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #818fa2;
  border-color: #0000;
}

.btn-outline-secondary:active .badge, .btn-outline-secondary.active .badge, .show > .btn-outline-secondary.dropdown-toggle .badge {
  color: #8897aa;
  background: #fff;
}

.btn-success {
  color: #fff;
  background: #02bc77;
  border-color: #0000;
}

.btn-success:hover {
  color: #fff;
  background: #02b371;
  border-color: #0000;
}

.btn-success:focus, .btn-success.focus {
  box-shadow: 0 0 0 2px #02bc7766;
}

.btn-success.disabled, .btn-success:disabled {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: #fff !important;
  background: #02bc77 !important;
  border-color: #0000 !important;
}

.btn-success .badge {
  color: #02bc77;
  background: #fff;
}

.btn-success:active, .btn-success.active, .show > .btn-success.dropdown-toggle {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #02a468;
  border-color: #0000;
}

.btn-group .btn-success, .input-group-prepend .btn-success, .input-group-append .btn-success {
  border-left: 1px solid #02a468;
  border-right: 1px solid #02a468;
}

.btn-outline-success {
  color: #02bc77;
  background: none;
  border-color: #02bc77;
}

.btn-outline-success:hover {
  color: #fff;
  background: #02bc77;
  border-color: #0000;
}

.btn-outline-success:hover .badge {
  color: #02bc77;
  background: #fff;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 2px #02bc7766;
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #02bc77 !important;
  background: none !important;
  border-color: #02bc77 !important;
}

.btn-outline-success .badge {
  color: #fff;
  background: #02bc77;
}

.btn-outline-success:active, .btn-outline-success.active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #02b371;
  border-color: #0000;
}

.btn-outline-success:active .badge, .btn-outline-success.active .badge, .show > .btn-outline-success.dropdown-toggle .badge {
  color: #02bc77;
  background: #fff;
}

.btn-info {
  color: #fff;
  background: #28c3d7;
  border-color: #0000;
}

.btn-info:hover {
  color: #fff;
  background: #26b9cc;
  border-color: #0000;
}

.btn-info:focus, .btn-info.focus {
  box-shadow: 0 0 0 2px #28c3d766;
}

.btn-info.disabled, .btn-info:disabled {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: #fff !important;
  background: #28c3d7 !important;
  border-color: #0000 !important;
}

.btn-info .badge {
  color: #28c3d7;
  background: #fff;
}

.btn-info:active, .btn-info.active, .show > .btn-info.dropdown-toggle {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #23aabb;
  border-color: #0000;
}

.btn-group .btn-info, .input-group-prepend .btn-info, .input-group-append .btn-info {
  border-left: 1px solid #23aabb;
  border-right: 1px solid #23aabb;
}

.btn-outline-info {
  color: #28c3d7;
  background: none;
  border-color: #28c3d7;
}

.btn-outline-info:hover {
  color: #fff;
  background: #28c3d7;
  border-color: #0000;
}

.btn-outline-info:hover .badge {
  color: #28c3d7;
  background: #fff;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 2px #28c3d766;
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #28c3d7 !important;
  background: none !important;
  border-color: #28c3d7 !important;
}

.btn-outline-info .badge {
  color: #fff;
  background: #28c3d7;
}

.btn-outline-info:active, .btn-outline-info.active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #26b9cc;
  border-color: #0000;
}

.btn-outline-info:active .badge, .btn-outline-info.active .badge, .show > .btn-outline-info.dropdown-toggle .badge {
  color: #28c3d7;
  background: #fff;
}

.btn-warning {
  color: #665720;
  background: #ffd950;
  border-color: #0000;
}

.btn-warning:hover {
  color: #665720;
  background: #f2ce4c;
  border-color: #0000;
}

.btn-warning:focus, .btn-warning.focus {
  box-shadow: 0 0 0 2px #ffd95066;
}

.btn-warning.disabled, .btn-warning:disabled {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: #665720 !important;
  background: #ffd950 !important;
  border-color: #0000 !important;
}

.btn-warning .badge {
  color: #ffd950;
  background: #665720;
}

.btn-warning:active, .btn-warning.active, .show > .btn-warning.dropdown-toggle {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #debd46;
  border-color: #0000;
}

.btn-group .btn-warning, .input-group-prepend .btn-warning, .input-group-append .btn-warning {
  border-left: 1px solid #debd46;
  border-right: 1px solid #debd46;
}

.btn-outline-warning {
  color: #ffd950;
  background: none;
  border-color: #ffd950;
}

.btn-outline-warning:hover {
  color: #665720;
  background: #ffd950;
  border-color: #0000;
}

.btn-outline-warning:hover .badge {
  color: #ffd950;
  background: #665720;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 2px #ffd95066;
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffd950 !important;
  background: none !important;
  border-color: #ffd950 !important;
}

.btn-outline-warning .badge {
  color: #fff;
  background: #ffd950;
}

.btn-outline-warning:active, .btn-outline-warning.active, .show > .btn-outline-warning.dropdown-toggle {
  color: #665720;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #f2ce4c;
  border-color: #0000;
}

.btn-outline-warning:active .badge, .btn-outline-warning.active .badge, .show > .btn-outline-warning.dropdown-toggle .badge {
  color: #ffd950;
  background: #665720;
}

.btn-danger {
  color: #fff;
  background: #d9534f;
  border-color: #0000;
}

.btn-danger:hover {
  color: #fff;
  background: #ce4f4b;
  border-color: #0000;
}

.btn-danger:focus, .btn-danger.focus {
  box-shadow: 0 0 0 2px #d9534f66;
}

.btn-danger.disabled, .btn-danger:disabled {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: #fff !important;
  background: #d9534f !important;
  border-color: #0000 !important;
}

.btn-danger .badge {
  color: #d9534f;
  background: #fff;
}

.btn-danger:active, .btn-danger.active, .show > .btn-danger.dropdown-toggle {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #bd4845;
  border-color: #0000;
}

.btn-group .btn-danger, .input-group-prepend .btn-danger, .input-group-append .btn-danger {
  border-left: 1px solid #bd4845;
  border-right: 1px solid #bd4845;
}

.btn-outline-danger {
  color: #d9534f;
  background: none;
  border-color: #d9534f;
}

.btn-outline-danger:hover {
  color: #fff;
  background: #d9534f;
  border-color: #0000;
}

.btn-outline-danger:hover .badge {
  color: #d9534f;
  background: #fff;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 2px #d9534f66;
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #d9534f !important;
  background: none !important;
  border-color: #d9534f !important;
}

.btn-outline-danger .badge {
  color: #fff;
  background: #d9534f;
}

.btn-outline-danger:active, .btn-outline-danger.active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #ce4f4b;
  border-color: #0000;
}

.btn-outline-danger:active .badge, .btn-outline-danger.active .badge, .show > .btn-outline-danger.dropdown-toggle .badge {
  color: #d9534f;
  background: #fff;
}

.btn-light {
  color: #0a0b0d;
  background: #181c210f;
  border-color: #0000;
}

.btn-light:hover {
  color: #0a0b0d;
  background: #181c211c;
  border-color: #0000;
}

.btn-light:focus, .btn-light.focus {
  box-shadow: 0 0 0 2px #181c2107;
}

.btn-light.disabled, .btn-light:disabled {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: #0a0b0d !important;
  background: #181c210f !important;
  border-color: #0000 !important;
}

.btn-light .badge {
  color: #f1f1f2;
  background: #0a0b0d;
}

.btn-light:active, .btn-light.active, .show > .btn-light.dropdown-toggle {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #181c2130;
  border-color: #0000;
}

.btn-group .btn-light, .input-group-prepend .btn-light, .input-group-append .btn-light {
  border-left: 1px solid #181c2130;
  border-right: 1px solid #181c2130;
}

.btn-outline-light {
  color: #181c210f;
  background: none;
  border-color: #181c210f;
}

.btn-outline-light:hover {
  color: #0a0b0d;
  background: #181c210f;
  border-color: #0000;
}

.btn-outline-light:hover .badge {
  color: #fff;
  background: #0a0b0d;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 2px #181c2107;
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #181c210f !important;
  background: none !important;
  border-color: #181c210f !important;
}

.btn-outline-light .badge {
  color: #fff;
  background: #181c210f;
}

.btn-outline-light:active, .btn-outline-light.active, .show > .btn-outline-light.dropdown-toggle {
  color: #0a0b0d;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #181c211c;
  border-color: #0000;
}

.btn-outline-light:active .badge, .btn-outline-light.active .badge, .show > .btn-outline-light.dropdown-toggle .badge {
  color: #fff;
  background: #0a0b0d;
}

.btn-dark {
  color: #fff;
  background: #181c21e6;
  border-color: #0000;
}

.btn-dark:hover {
  color: #fff;
  background: #181c21f2;
  border-color: #0000;
}

.btn-dark:focus, .btn-dark.focus {
  box-shadow: 0 0 0 2px #181c2167;
}

.btn-dark.disabled, .btn-dark:disabled {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: #fff !important;
  background: #181c21e6 !important;
  border-color: #0000 !important;
}

.btn-dark .badge {
  color: #2f3337;
  background: #fff;
}

.btn-dark:active, .btn-dark.active, .show > .btn-dark.dropdown-toggle {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #181c21;
  border-color: #0000;
}

.btn-group .btn-dark, .input-group-prepend .btn-dark, .input-group-append .btn-dark {
  border-left: 1px solid #181c21;
  border-right: 1px solid #181c21;
}

.btn-outline-dark {
  color: #181c21e6;
  background: none;
  border-color: #181c21e6;
}

.btn-outline-dark:hover {
  color: #fff;
  background: #181c21e6;
  border-color: #0000;
}

.btn-outline-dark:hover .badge {
  color: #666;
  background: #fff;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 2px #181c2167;
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #181c21e6 !important;
  background: none !important;
  border-color: #181c21e6 !important;
}

.btn-outline-dark .badge {
  color: #fff;
  background: #181c21e6;
}

.btn-outline-dark:active, .btn-outline-dark.active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #181c21f2;
  border-color: #0000;
}

.btn-outline-dark:active .badge, .btn-outline-dark.active .badge, .show > .btn-outline-dark.dropdown-toggle .badge {
  color: #666;
  background: #fff;
}

.btn-default {
  color: #4e5155;
  background: none;
  border-color: #181c211a;
}

.btn-default:hover {
  color: #4e5155;
  background: #181c210f;
  border-color: #181c211a;
}

.btn-default:focus, .btn-default.focus {
  box-shadow: 0 0 0 2px #0203030d;
}

.btn-default.disabled, .btn-default:disabled {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: #4e5155 !important;
  background: none !important;
  border-color: #181c211a !important;
}

.btn-default .badge {
  color: #fff;
  background: #4e5155;
}

.btn-default:active, .btn-default.active, .show > .btn-default.dropdown-toggle {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #181c211f;
  border-color: #181c211a;
}

.btn-group .btn-default, .input-group-prepend .btn-default, .input-group-append .btn-default {
  border-left: 1px solid #181c211f;
  border-right: 1px solid #181c211f;
}

.btn-outline-default {
  color: #181c211a;
  background: none;
  border-color: #181c211a;
}

.btn-outline-default:hover {
  color: #4e5155;
  background: #181c211a;
  border-color: #0000;
}

.btn-outline-default:hover .badge {
  color: #fff;
  background: #4e5155;
}

.btn-outline-default:focus, .btn-outline-default.focus {
  box-shadow: 0 0 0 2px #181c210b;
}

.btn-outline-default.disabled, .btn-outline-default:disabled {
  color: #181c211a !important;
  background: none !important;
  border-color: #181c211a !important;
}

.btn-outline-default .badge {
  color: #fff;
  background: #181c211a;
}

.btn-outline-default:active, .btn-outline-default.active, .show > .btn-outline-default.dropdown-toggle {
  color: #4e5155;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #181c2126;
  border-color: #0000;
}

.btn-outline-default:active .badge, .btn-outline-default.active .badge, .show > .btn-outline-default.dropdown-toggle .badge {
  color: #fff;
  background: #4e5155;
}

.btn-xl, .btn-group-xl > .btn {
  border-radius: .25rem;
  padding: .875rem 2.125rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.btn-lg {
  font-size: 1rem;
}

.btn-sm {
  font-size: .75rem;
}

.btn-xs, .btn-group-xs > .btn {
  border-radius: .25rem;
  padding: 0 .5rem;
  font-size: .75rem;
  line-height: 1.5;
}

.btn-round {
  border-radius: 60rem !important;
}

.icon-btn {
  padding: 0;
  line-height: 2.25276rem;
  width: calc(2.25276rem + 2px) !important;
}

.icon-btn.btn-xl, .btn-group-xl > .icon-btn.btn {
  line-height: 3.625rem;
  width: calc(3.625rem + 2px) !important;
}

.icon-btn.btn-lg {
  line-height: 3rem;
  width: calc(3rem + 2px) !important;
}

.icon-btn.btn-sm {
  line-height: 1.501rem;
  width: calc(1.501rem + 2px) !important;
}

.icon-btn.btn-xs, .btn-group-xs > .icon-btn.btn {
  line-height: 1.125rem;
  width: calc(1.125rem + 2px) !important;
}

.btn.borderless:not(.active):not(:active):not(:hover):not(:focus), :not(.show) > .btn.borderless.dropdown-toggle:not(:hover):not(:focus) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border-color: #0000 !important;
}

:not(.btn-group) > .btn-group > .btn:not(.btn-default):not([class*="btn-outline-"]):first-child, .btn-group > .btn-group:first-child > .btn:not(.btn-default):not([class*="btn-outline-"]):first-child, .input-group-prepend > .btn:not(.btn-default):not([class*="btn-outline-"]):first-child, .input-group-append > .btn:not(.btn-default):not([class*="btn-outline-"]):first-child {
  border-left-color: #0000 !important;
}

:not(.btn-group) > .btn-group > .btn:not(.btn-default):not([class*="btn-outline-"]):last-child, .btn-group > .btn-group:last-child > .btn:not(.btn-default):not([class*="btn-outline-"]):last-child, :not(.btn-group) > .btn-group > .dropdown-toggle:not(.btn-default):not([class*="btn-outline-"]), .btn-group > .btn-group:last-child > .dropdown-toggle:not(.btn-default):not([class*="btn-outline-"]), .input-group-prepend > .btn:not(.btn-default):not([class*="btn-outline-"]):last-child, .input-group-append > .btn:not(.btn-default):not([class*="btn-outline-"]):last-child, .input-group-prepend > .dropdown-toggle:not(.btn-default):not([class*="btn-outline-"]), .input-group-append > .dropdown-toggle:not(.btn-default):not([class*="btn-outline-"]) {
  border-right-color: #0000 !important;
}

.btn-group-sm > .btn {
  font-size: .75rem;
}

.btn-group-lg > .btn {
  font-size: 1rem;
}

.dropdown-toggle-split, .btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split, .input-group-lg .btn + .dropdown-toggle-split, .btn-xl + .dropdown-toggle-split, .btn-group-xl > .btn + .dropdown-toggle-split, .btn-group-xl > .btn + .dropdown-toggle-split {
  padding-left: .7em;
  padding-right: .7em;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split, .input-group-sm .btn + .dropdown-toggle-split {
  padding-left: .6em;
  padding-right: .6em;
}

.btn-xs + .dropdown-toggle-split, .btn-group-xs > .btn + .dropdown-toggle-split, .btn-group-xs > .btn + .dropdown-toggle-split {
  padding-left: .5em;
  padding-right: .5em;
}

.badge-secondary {
  color: #fff;
  background-color: #8897aa;
}

.badge-secondary[href]:hover, .badge-secondary[href]:focus {
  color: #fff;
  background-color: #818fa2;
  text-decoration: none;
}

.btn .badge-secondary {
  color: #fff !important;
  background-color: #8897aa !important;
}

.badge-outline-secondary {
  color: #8897aa;
  background-color: #0000;
  box-shadow: inset 0 0 0 1px #8897aa;
}

.badge-outline-secondary[href]:hover, .badge-outline-secondary[href]:focus {
  color: #8897aa;
  text-decoration: none;
}

.btn .badge-outline-secondary {
  color: #8897aa !important;
  background-color: #0000 !important;
  box-shadow: inset 0 0 0 1px #8897aa !important;
}

.badge-success {
  color: #fff;
  background-color: #02bc77;
}

.badge-success[href]:hover, .badge-success[href]:focus {
  color: #fff;
  background-color: #02b371;
  text-decoration: none;
}

.btn .badge-success {
  color: #fff !important;
  background-color: #02bc77 !important;
}

.badge-outline-success {
  color: #02bc77;
  background-color: #0000;
  box-shadow: inset 0 0 0 1px #02bc77;
}

.badge-outline-success[href]:hover, .badge-outline-success[href]:focus {
  color: #02bc77;
  text-decoration: none;
}

.btn .badge-outline-success {
  color: #02bc77 !important;
  background-color: #0000 !important;
  box-shadow: inset 0 0 0 1px #02bc77 !important;
}

.badge-info {
  color: #fff;
  background-color: #28c3d7;
}

.badge-info[href]:hover, .badge-info[href]:focus {
  color: #fff;
  background-color: #26b9cc;
  text-decoration: none;
}

.btn .badge-info {
  color: #fff !important;
  background-color: #28c3d7 !important;
}

.badge-outline-info {
  color: #28c3d7;
  background-color: #0000;
  box-shadow: inset 0 0 0 1px #28c3d7;
}

.badge-outline-info[href]:hover, .badge-outline-info[href]:focus {
  color: #28c3d7;
  text-decoration: none;
}

.btn .badge-outline-info {
  color: #28c3d7 !important;
  background-color: #0000 !important;
  box-shadow: inset 0 0 0 1px #28c3d7 !important;
}

.badge-warning {
  color: #665720;
  background-color: #ffd950;
}

.badge-warning[href]:hover, .badge-warning[href]:focus {
  color: #665720;
  background-color: #f2ce4c;
  text-decoration: none;
}

.btn .badge-warning {
  color: #665720 !important;
  background-color: #ffd950 !important;
}

.badge-outline-warning {
  color: #ffd950;
  background-color: #0000;
  box-shadow: inset 0 0 0 1px #ffd950;
}

.badge-outline-warning[href]:hover, .badge-outline-warning[href]:focus {
  color: #ffd950;
  text-decoration: none;
}

.btn .badge-outline-warning {
  color: #ffd950 !important;
  background-color: #0000 !important;
  box-shadow: inset 0 0 0 1px #ffd950 !important;
}

.badge-danger {
  color: #fff;
  background-color: #d9534f;
}

.badge-danger[href]:hover, .badge-danger[href]:focus {
  color: #fff;
  background-color: #ce4f4b;
  text-decoration: none;
}

.btn .badge-danger {
  color: #fff !important;
  background-color: #d9534f !important;
}

.badge-outline-danger {
  color: #d9534f;
  background-color: #0000;
  box-shadow: inset 0 0 0 1px #d9534f;
}

.badge-outline-danger[href]:hover, .badge-outline-danger[href]:focus {
  color: #d9534f;
  text-decoration: none;
}

.btn .badge-outline-danger {
  color: #d9534f !important;
  background-color: #0000 !important;
  box-shadow: inset 0 0 0 1px #d9534f !important;
}

.badge-dark {
  color: #fff;
  background-color: #181c21e6;
}

.badge-dark[href]:hover, .badge-dark[href]:focus {
  color: #fff;
  background-color: #181c21ed;
  text-decoration: none;
}

.btn .badge-dark {
  color: #fff !important;
  background-color: #181c21e6 !important;
}

.badge-outline-dark {
  color: #181c21e6;
  background-color: #0000;
  box-shadow: inset 0 0 0 1px #181c21e6;
}

.badge-outline-dark[href]:hover, .badge-outline-dark[href]:focus {
  color: #181c21e6;
  text-decoration: none;
}

.btn .badge-outline-dark {
  color: #181c21e6 !important;
  background-color: #0000 !important;
  box-shadow: inset 0 0 0 1px #181c21e6 !important;
}

.badge-default {
  color: #4e5155;
  background-color: #181c211a;
}

.badge-default[href]:hover, .badge-default[href]:focus {
  color: #4e5155;
  background-color: #181c2121;
  text-decoration: none;
}

.btn .badge-default {
  color: #4e5155 !important;
  background-color: #181c211a !important;
}

.badge-outline-default {
  color: #4e5155;
  background-color: #0000;
  box-shadow: inset 0 0 0 1px #181c211a;
}

.badge-outline-default[href]:hover, .badge-outline-default[href]:focus {
  color: #4e5155;
  text-decoration: none;
}

.btn .badge-outline-default {
  color: #4e5155 !important;
  background-color: #0000 !important;
  box-shadow: inset 0 0 0 1px #181c211a !important;
}

.badge.badge-dot {
  width: .5em;
  height: .5em;
  vertical-align: middle;
  border-radius: 50%;
  margin: 0;
  padding: 0;
  display: inline-block;
}

.badge.indicator {
  margin: 0;
  display: inline-block;
  position: absolute;
  top: auto;
  transform: translate(-50%, 25%);
}

.badge.indicator:not(.badge-dot) {
  padding: 0 .2em;
  font-size: .6em;
  line-height: 1.2em;
  transform: translate(-50%, -10%);
}

[data-trigger="hover"] {
  outline: 0;
}

.dropdown-menu {
  margin: .125rem 0;
  box-shadow: 0 1px 6px #00000017;
}

.mega-dropdown > .dropdown-menu {
  left: 0 !important;
  right: 0 !important;
}

.dropdown-item {
  line-height: 1.54;
}

.dropdown-menu .badge[class^="float-"], .dropdown-menu .badge[class*=" float-"] {
  position: relative;
  top: .071em;
}

.dropdown-toggle.hide-arrow:before, .dropdown-toggle.hide-arrow:after, .dropdown-toggle-hide-arrow > .dropdown-toggle:before, .dropdown-toggle-hide-arrow > .dropdown-toggle:after {
  display: none !important;
}

.dropdown-menu > li:not(.dropdown-item) > a:not(.dropdown-item) {
  clear: both;
  width: 100%;
  color: #4e5155;
  text-align: inherit;
  white-space: nowrap;
  background: none;
  border: 0;
  padding: .438rem 1.25rem;
  font-weight: 400;
  line-height: 1.54;
  display: block;
}

.dropdown-menu > li:not(.dropdown-item) > a:not(.dropdown-item):hover, .dropdown-menu > li:not(.dropdown-item) > a:not(.dropdown-item):focus {
  color: #4e5155;
  background-color: #181c2108;
  text-decoration: none;
}

.dropdown-menu > li:not(.dropdown-item).active > a:not(.dropdown-item), .dropdown-menu > li:not(.dropdown-item) > a:not(.dropdown-item):active {
  color: #fff;
  background-color: #02bc77;
  text-decoration: none;
}

.dropdown-menu > li:not(.dropdown-item).disabled > a:not(.dropdown-item) {
  color: #d1d2d3;
  background-color: #0000;
}

.dropdown-toggle:after, .dropdown-toggle:before {
  vertical-align: middle !important;
}

.dropdown-toggle:after, .dropup .dropdown-toggle:after, .dropright .dropdown-toggle:after {
  margin-left: .5em;
}

.dropleft .dropdown-toggle:before {
  margin-right: .5em;
}

.dropdown-toggle-split:after {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.dropdown-menu .dropdown-toggle {
  position: relative;
}

.dropdown-menu .dropdown-toggle:after {
  display: none;
}

.dropdown-menu .dropdown-toggle > .dropdown-item {
  cursor: default;
  position: relative;
}

@media (max-width: 767px) {
  .dropdown-menu .dropdown-menu {
    width: 100%;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-width: 0;
    border-radius: 0;
    margin: 0;
    padding-left: 1.25rem;
    display: block;
    position: static;
  }
}

@media (min-width: 768px) {
  .dropdown-menu .dropdown-toggle > .dropdown-item:after {
    content: "";
    width: .375rem;
    height: .375rem;
    border: 1px solid #a3a4a6;
    border-top: 0;
    border-left: 0;
    display: block;
    position: absolute;
    top: 50%;
    right: .625rem;
    transform: rotate(-45deg)translate(0, -50%);
  }

  .dropdown-menu .dropdown-toggle:hover > .dropdown-item:not(.disabled) {
    color: #4e5155;
    background: #181c2108;
  }

  .dropdown-menu .dropdown-toggle:hover > .dropdown-item:not(.disabled) + .dropdown-menu {
    display: block;
  }

  .dropdown-menu .dropdown-menu {
    top: 0;
    left: 100%;
  }

  .dropdown-menu-right .dropdown-menu {
    left: auto;
    right: 100%;
  }
}

.nav-link {
  line-height: 1.54;
}

.nav-link.disabled {
  color: #d1d2d3 !important;
  background: none !important;
  border-color: #0000 !important;
}

.nav-tabs .nav-link:not(.active), .nav-pills .nav-link:not(.active) {
  color: #8c8e90;
}

.nav-tabs .nav-link:not(.active):hover, .nav-tabs .nav-link:not(.active):focus, .nav-pills .nav-link:not(.active):hover, .nav-pills .nav-link:not(.active):focus {
  color: #4e5155;
}

.nav-tabs:not(.nav-fill):not(.nav-justified) .nav-link, .nav-pills:not(.nav-fill):not(.nav-justified) .nav-link {
  margin-right: .125rem;
}

.nav-tabs .nav-link .badge, .nav-pills .nav-link .badge {
  position: relative;
  top: -1px;
}

.nav-tabs .nav-item.show .nav-link:not(.active), .nav-pills .nav-item.show .nav-link:not(.active) {
  background-color: #0000;
}

.nav-tabs .nav-link {
  background-clip: padding-box;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-bottom-color: #0000;
}

.nav-tabs .nav-link.active {
  border-bottom-color: #fff;
}

.nav-tabs.tabs-alt .nav-link, .tabs-alt > .nav-tabs .nav-link {
  background-color: #0000 !important;
  border-width: 0 !important;
  border-radius: 0 !important;
}

.nav-lg > .nav .nav-link, .nav-lg.nav .nav-link {
  padding: .875rem 1.3125rem;
  font-size: 1rem;
  line-height: 1.5;
}

.nav-sm > .nav .nav-link, .nav-sm.nav .nav-link {
  padding: .3125rem .875rem;
  font-size: .75rem;
  line-height: 1.5;
}

.nav-tabs-top, .nav-tabs-right, .nav-tabs-bottom, .nav-tabs-left {
  display: flex;
}

.nav-tabs-top > .nav, .nav-tabs-top > div > .nav, .nav-tabs-right > .nav, .nav-tabs-right > div > .nav, .nav-tabs-bottom > .nav, .nav-tabs-bottom > div > .nav, .nav-tabs-left > .nav, .nav-tabs-left > div > .nav {
  z-index: 1;
  border: 0;
  position: relative;
}

.nav-tabs-top > .nav .nav-link:hover, .nav-tabs-top > .nav .nav-link:focus, .nav-tabs-top > div > .nav .nav-link:hover, .nav-tabs-top > div > .nav .nav-link:focus, .nav-tabs-right > .nav .nav-link:hover, .nav-tabs-right > .nav .nav-link:focus, .nav-tabs-right > div > .nav .nav-link:hover, .nav-tabs-right > div > .nav .nav-link:focus, .nav-tabs-bottom > .nav .nav-link:hover, .nav-tabs-bottom > .nav .nav-link:focus, .nav-tabs-bottom > div > .nav .nav-link:hover, .nav-tabs-bottom > div > .nav .nav-link:focus, .nav-tabs-left > .nav .nav-link:hover, .nav-tabs-left > .nav .nav-link:focus, .nav-tabs-left > div > .nav .nav-link:hover, .nav-tabs-left > div > .nav .nav-link:focus, .nav-tabs-top > .nav .nav-link.active, .nav-tabs-top > div > .nav .nav-link.active, .nav-tabs-right > .nav .nav-link.active, .nav-tabs-right > div > .nav .nav-link.active, .nav-tabs-bottom > .nav .nav-link.active, .nav-tabs-bottom > div > .nav .nav-link.active, .nav-tabs-left > .nav .nav-link.active, .nav-tabs-left > div > .nav .nav-link.active {
  border-color: #181c210f;
}

.nav-tabs-right, .nav-tabs-left {
  align-items: stretch;
}

.nav-tabs-right > .nav, .nav-tabs-right > div > .nav, .nav-tabs-left > .nav, .nav-tabs-left > div > .nav {
  flex-direction: column;
  flex-grow: 0;
}

.nav-tabs-right > .nav .nav-link, .nav-tabs-right > div > .nav .nav-link, .nav-tabs-left > .nav .nav-link, .nav-tabs-left > div > .nav .nav-link {
  margin: 0 0 .125rem !important;
}

.nav-tabs-right > .tab-content, .nav-tabs-left > .tab-content {
  flex-grow: 1;
}

.nav-tabs-top {
  flex-direction: column;
}

.nav-tabs-top > .nav .nav-link:hover, .nav-tabs-top > .nav .nav-link:focus, .nav-tabs-top > div > .nav .nav-link:hover, .nav-tabs-top > div > .nav .nav-link:focus {
  border-bottom-color: #0000;
}

.nav-tabs-top > .nav .nav-link.active, .nav-tabs-top > div > .nav .nav-link.active {
  border-bottom-color: #fff;
}

.nav-tabs-right {
  flex-direction: row-reverse;
}

.nav-tabs-right > .nav .nav-item, .nav-tabs-right > div > .nav .nav-item {
  margin-bottom: 0;
  margin-left: -1px;
}

.nav-tabs-right > .nav .nav-link, .nav-tabs-right > div > .nav .nav-link {
  border-radius: 0 .25rem .25rem 0;
}

.nav-tabs-right > .nav .nav-link:hover, .nav-tabs-right > .nav .nav-link:focus, .nav-tabs-right > div > .nav .nav-link:hover, .nav-tabs-right > div > .nav .nav-link:focus {
  border-left-color: #0000;
}

.nav-tabs-right > .nav .nav-link.active, .nav-tabs-right > div > .nav .nav-link.active {
  border-left-color: #fff;
}

.nav-tabs-bottom {
  flex-direction: column-reverse;
}

.nav-tabs-bottom > .nav .nav-item, .nav-tabs-bottom > div > .nav .nav-item {
  margin-top: -1px;
  margin-bottom: 0;
}

.nav-tabs-bottom > .nav .nav-link, .nav-tabs-bottom > div > .nav .nav-link {
  border-radius: 0 0 .25rem .25rem;
}

.nav-tabs-bottom > .nav .nav-link:hover, .nav-tabs-bottom > .nav .nav-link:focus, .nav-tabs-bottom > div > .nav .nav-link:hover, .nav-tabs-bottom > div > .nav .nav-link:focus {
  border-top-color: #0000;
}

.nav-tabs-bottom > .nav .nav-link.active, .nav-tabs-bottom > div > .nav .nav-link.active {
  border-top-color: #fff;
}

.nav-tabs-left > .nav .nav-item, .nav-tabs-left > div > .nav .nav-item {
  margin-bottom: 0;
  margin-right: -1px;
}

.nav-tabs-left > .nav .nav-link, .nav-tabs-left > div > .nav .nav-link {
  border-radius: .25rem 0 0 .25rem;
}

.nav-tabs-left > .nav .nav-link:hover, .nav-tabs-left > .nav .nav-link:focus, .nav-tabs-left > div > .nav .nav-link:hover, .nav-tabs-left > div > .nav .nav-link:focus {
  border-right-color: #0000;
}

.nav-tabs-left > .nav .nav-link.active, .nav-tabs-left > div > .nav .nav-link.active {
  border-right-color: #fff;
}

.nav-tabs-top > .tab-content, .nav-tabs-right > .tab-content, .nav-tabs-bottom > .tab-content, .nav-tabs-left > .tab-content {
  background: #fff padding-box padding-box;
  border: 1px solid #181c210f;
  flex-shrink: 1;
  box-shadow: 0 1px 4px #181c2102;
}

.nav-tabs-top > .tab-content {
  border-radius: 0 0 .25rem .25rem;
}

.nav-tabs-right > .tab-content {
  border-radius: .25rem 0 0 .25rem;
}

.nav-tabs-bottom > .tab-content {
  border-radius: .25rem .25rem 0 0;
}

.nav-tabs-left > .tab-content {
  border-radius: 0 .25rem .25rem 0;
}

@media (max-width: 575px) {
  .nav-responsive-sm > .nav, .nav-responsive-sm > div > .nav {
    border: 0;
    flex-direction: column;
  }

  .nav-responsive-sm > .nav .nav-item, .nav-responsive-sm > div > .nav .nav-item {
    flex-basis: auto;
  }

  .nav-responsive-sm > .nav.card-header-tabs, .nav-responsive-sm > .nav.card-header-pills, .nav-responsive-sm > div > .nav.card-header-tabs, .nav-responsive-sm > div > .nav.card-header-pills {
    margin-bottom: -.125rem;
  }

  .nav-responsive-sm > .nav-tabs .nav-item, .nav-responsive-sm > .nav-pills .nav-item, .nav-responsive-sm > div > .nav-tabs .nav-item, .nav-responsive-sm > div > .nav-pills .nav-item {
    margin: 0 !important;
  }

  .nav-responsive-sm > .nav-tabs .nav-link, .nav-responsive-sm > .nav-pills .nav-link, .nav-responsive-sm > div > .nav-tabs .nav-link, .nav-responsive-sm > div > .nav-pills .nav-link {
    margin: 0 0 .125rem !important;
  }

  .nav-responsive-sm > .nav-tabs:not(.tabs-alt) .nav-link, .nav-responsive-sm > div > .nav-tabs:not(.tabs-alt) .nav-link {
    border-radius: .25rem !important;
  }

  .nav-responsive-sm > .nav-tabs:not(.tabs-alt) .nav-link:hover, .nav-responsive-sm > .nav-tabs:not(.tabs-alt) .nav-link:focus, .nav-responsive-sm > div > .nav-tabs:not(.tabs-alt) .nav-link:hover, .nav-responsive-sm > div > .nav-tabs:not(.tabs-alt) .nav-link:focus, .nav-responsive-sm > .nav-tabs:not(.tabs-alt) .nav-link.active, .nav-responsive-sm > div > .nav-tabs:not(.tabs-alt) .nav-link.active {
    border-color: #181c210f !important;
  }

  .nav-responsive-sm.nav-tabs-top > .tab-content, .nav-responsive-sm.nav-tabs-right > .tab-content, .nav-responsive-sm.nav-tabs-bottom > .tab-content, .nav-responsive-sm.nav-tabs-left > .tab-content {
    border-radius: .25rem !important;
  }

  .nav-responsive-sm.nav-tabs-bottom, .nav-responsive-sm.nav-tabs-right, .nav-responsive-sm.nav-tabs-left {
    flex-direction: column !important;
  }
}

@media (max-width: 767px) {
  .nav-responsive-md > .nav, .nav-responsive-md > div > .nav {
    border: 0;
    flex-direction: column;
  }

  .nav-responsive-md > .nav .nav-item, .nav-responsive-md > div > .nav .nav-item {
    flex-basis: auto;
  }

  .nav-responsive-md > .nav.card-header-tabs, .nav-responsive-md > .nav.card-header-pills, .nav-responsive-md > div > .nav.card-header-tabs, .nav-responsive-md > div > .nav.card-header-pills {
    margin-bottom: -.125rem;
  }

  .nav-responsive-md > .nav-tabs .nav-item, .nav-responsive-md > .nav-pills .nav-item, .nav-responsive-md > div > .nav-tabs .nav-item, .nav-responsive-md > div > .nav-pills .nav-item {
    margin: 0 !important;
  }

  .nav-responsive-md > .nav-tabs .nav-link, .nav-responsive-md > .nav-pills .nav-link, .nav-responsive-md > div > .nav-tabs .nav-link, .nav-responsive-md > div > .nav-pills .nav-link {
    margin: 0 0 .125rem !important;
  }

  .nav-responsive-md > .nav-tabs:not(.tabs-alt) .nav-link, .nav-responsive-md > div > .nav-tabs:not(.tabs-alt) .nav-link {
    border-radius: .25rem !important;
  }

  .nav-responsive-md > .nav-tabs:not(.tabs-alt) .nav-link:hover, .nav-responsive-md > .nav-tabs:not(.tabs-alt) .nav-link:focus, .nav-responsive-md > div > .nav-tabs:not(.tabs-alt) .nav-link:hover, .nav-responsive-md > div > .nav-tabs:not(.tabs-alt) .nav-link:focus, .nav-responsive-md > .nav-tabs:not(.tabs-alt) .nav-link.active, .nav-responsive-md > div > .nav-tabs:not(.tabs-alt) .nav-link.active {
    border-color: #181c210f !important;
  }

  .nav-responsive-md.nav-tabs-top > .tab-content, .nav-responsive-md.nav-tabs-right > .tab-content, .nav-responsive-md.nav-tabs-bottom > .tab-content, .nav-responsive-md.nav-tabs-left > .tab-content {
    border-radius: .25rem !important;
  }

  .nav-responsive-md.nav-tabs-bottom, .nav-responsive-md.nav-tabs-right, .nav-responsive-md.nav-tabs-left {
    flex-direction: column !important;
  }
}

@media (max-width: 991px) {
  .nav-responsive-lg > .nav, .nav-responsive-lg > div > .nav {
    border: 0;
    flex-direction: column;
  }

  .nav-responsive-lg > .nav .nav-item, .nav-responsive-lg > div > .nav .nav-item {
    flex-basis: auto;
  }

  .nav-responsive-lg > .nav.card-header-tabs, .nav-responsive-lg > .nav.card-header-pills, .nav-responsive-lg > div > .nav.card-header-tabs, .nav-responsive-lg > div > .nav.card-header-pills {
    margin-bottom: -.125rem;
  }

  .nav-responsive-lg > .nav-tabs .nav-item, .nav-responsive-lg > .nav-pills .nav-item, .nav-responsive-lg > div > .nav-tabs .nav-item, .nav-responsive-lg > div > .nav-pills .nav-item {
    margin: 0 !important;
  }

  .nav-responsive-lg > .nav-tabs .nav-link, .nav-responsive-lg > .nav-pills .nav-link, .nav-responsive-lg > div > .nav-tabs .nav-link, .nav-responsive-lg > div > .nav-pills .nav-link {
    margin: 0 0 .125rem !important;
  }

  .nav-responsive-lg > .nav-tabs:not(.tabs-alt) .nav-link, .nav-responsive-lg > div > .nav-tabs:not(.tabs-alt) .nav-link {
    border-radius: .25rem !important;
  }

  .nav-responsive-lg > .nav-tabs:not(.tabs-alt) .nav-link:hover, .nav-responsive-lg > .nav-tabs:not(.tabs-alt) .nav-link:focus, .nav-responsive-lg > div > .nav-tabs:not(.tabs-alt) .nav-link:hover, .nav-responsive-lg > div > .nav-tabs:not(.tabs-alt) .nav-link:focus, .nav-responsive-lg > .nav-tabs:not(.tabs-alt) .nav-link.active, .nav-responsive-lg > div > .nav-tabs:not(.tabs-alt) .nav-link.active {
    border-color: #181c210f !important;
  }

  .nav-responsive-lg.nav-tabs-top > .tab-content, .nav-responsive-lg.nav-tabs-right > .tab-content, .nav-responsive-lg.nav-tabs-bottom > .tab-content, .nav-responsive-lg.nav-tabs-left > .tab-content {
    border-radius: .25rem !important;
  }

  .nav-responsive-lg.nav-tabs-bottom, .nav-responsive-lg.nav-tabs-right, .nav-responsive-lg.nav-tabs-left {
    flex-direction: column !important;
  }
}

@media (max-width: 1199px) {
  .nav-responsive-xl > .nav, .nav-responsive-xl > div > .nav {
    border: 0;
    flex-direction: column;
  }

  .nav-responsive-xl > .nav .nav-item, .nav-responsive-xl > div > .nav .nav-item {
    flex-basis: auto;
  }

  .nav-responsive-xl > .nav.card-header-tabs, .nav-responsive-xl > .nav.card-header-pills, .nav-responsive-xl > div > .nav.card-header-tabs, .nav-responsive-xl > div > .nav.card-header-pills {
    margin-bottom: -.125rem;
  }

  .nav-responsive-xl > .nav-tabs .nav-item, .nav-responsive-xl > .nav-pills .nav-item, .nav-responsive-xl > div > .nav-tabs .nav-item, .nav-responsive-xl > div > .nav-pills .nav-item {
    margin: 0 !important;
  }

  .nav-responsive-xl > .nav-tabs .nav-link, .nav-responsive-xl > .nav-pills .nav-link, .nav-responsive-xl > div > .nav-tabs .nav-link, .nav-responsive-xl > div > .nav-pills .nav-link {
    margin: 0 0 .125rem !important;
  }

  .nav-responsive-xl > .nav-tabs:not(.tabs-alt) .nav-link, .nav-responsive-xl > div > .nav-tabs:not(.tabs-alt) .nav-link {
    border-radius: .25rem !important;
  }

  .nav-responsive-xl > .nav-tabs:not(.tabs-alt) .nav-link:hover, .nav-responsive-xl > .nav-tabs:not(.tabs-alt) .nav-link:focus, .nav-responsive-xl > div > .nav-tabs:not(.tabs-alt) .nav-link:hover, .nav-responsive-xl > div > .nav-tabs:not(.tabs-alt) .nav-link:focus, .nav-responsive-xl > .nav-tabs:not(.tabs-alt) .nav-link.active, .nav-responsive-xl > div > .nav-tabs:not(.tabs-alt) .nav-link.active {
    border-color: #181c210f !important;
  }

  .nav-responsive-xl.nav-tabs-top > .tab-content, .nav-responsive-xl.nav-tabs-right > .tab-content, .nav-responsive-xl.nav-tabs-bottom > .tab-content, .nav-responsive-xl.nav-tabs-left > .tab-content {
    border-radius: .25rem !important;
  }

  .nav-responsive-xl.nav-tabs-bottom, .nav-responsive-xl.nav-tabs-right, .nav-responsive-xl.nav-tabs-left {
    flex-direction: column !important;
  }
}

.page-item + .page-item .page-link, .pagination li + li > a:not(.page-link) {
  margin-left: .1875rem;
}

.page-link, .page-link > a {
  min-width: calc(2.144rem + 2px);
  text-align: center;
  border-radius: .25rem;
  line-height: 1 !important;
}

.page-link:focus, .page-link > a:focus {
  color: #4e5155;
}

.page-link.btn-primary {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.pagination > li > a:not(.page-link) {
  min-width: calc(2.144rem + 2px);
  color: #a3a4a6;
  text-align: center;
  background-color: #0000;
  border: 1px solid #181c210f;
  border-radius: .25rem;
  padding: .625rem .3125rem;
  display: block;
  position: relative;
  line-height: 1 !important;
}

.pagination > li > a:not(.page-link):hover, .pagination > li > a:not(.page-link):focus {
  color: #4e5155;
  background-color: #0000;
  border-color: #181c211a;
  text-decoration: none;
}

.pagination > li > a:not(.page-link):focus {
  color: #4e5155;
}

.pagination-lg .page-link, .pagination-lg > li > a:not(.page-link) {
  min-width: calc(2.875rem + 2px);
  border-radius: .25rem;
}

.pagination-sm .page-link, .pagination-sm > li > a:not(.page-link) {
  min-width: calc(1.5rem + 2px);
  border-radius: .25rem;
}

.alert-secondary {
  color: #5f6061;
  background-color: #edeff2;
  border-color: #dfe1e3;
}

.alert-secondary hr {
  border-top-color: #dfe1e3;
}

.alert-secondary .close, .alert-secondary .alert-link {
  color: #5f6061;
}

.alert-dark-secondary {
  color: #fff;
  background-color: #8897aa;
}

.alert-dark-secondary hr {
  border-top-color: #fff3;
}

.alert-dark-secondary .close, .alert-dark-secondary .alert-link {
  color: #fff;
}

.alert-success {
  color: #57625e;
  background-color: #d9f5eb;
  border-color: #cce6dd;
}

.alert-success hr {
  border-top-color: #cce6dd;
}

.alert-success .close, .alert-success .alert-link {
  color: #57625e;
}

.alert-dark-success {
  color: #fff;
  background-color: #02bc77;
}

.alert-dark-success hr {
  border-top-color: #fff3;
}

.alert-dark-success .close, .alert-dark-success .alert-link {
  color: #fff;
}

.alert-info {
  color: #596264;
  background-color: #dff6f9;
  border-color: #d2e7ea;
}

.alert-info hr {
  border-top-color: #d2e7ea;
}

.alert-info .close, .alert-info .alert-link {
  color: #596264;
}

.alert-dark-info {
  color: #fff;
  background-color: #28c3d7;
}

.alert-dark-info hr {
  border-top-color: #fff3;
}

.alert-dark-info .close, .alert-dark-info .alert-link {
  color: #fff;
}

.alert-warning {
  color: #66645c;
  background-color: #fff9e5;
  border-color: #f0ead7;
}

.alert-warning hr {
  border-top-color: #f0ead7;
}

.alert-warning .close, .alert-warning .alert-link {
  color: #66645c;
}

.alert-dark-warning {
  color: #665720;
  background-color: #ffd950;
}

.alert-dark-warning hr {
  border-top-color: #66572033;
}

.alert-dark-warning .close, .alert-dark-warning .alert-link {
  color: #665720;
}

.alert-danger {
  color: #645c5c;
  background-color: #f9e5e5;
  border-color: #ead7d7;
}

.alert-danger hr {
  border-top-color: #ead7d7;
}

.alert-danger .close, .alert-danger .alert-link {
  color: #645c5c;
}

.alert-dark-danger {
  color: #fff;
  background-color: #d9534f;
}

.alert-dark-danger hr {
  border-top-color: #fff3;
}

.alert-dark-danger .close, .alert-dark-danger .alert-link {
  color: #fff;
}

.alert-dark {
  color: #585859;
  background-color: #dcddde;
  border-color: #cfd0d1;
}

.alert-dark hr {
  border-top-color: #cfd0d1;
}

.alert-dark .close, .alert-dark .alert-link {
  color: #585859;
}

.alert-dark-dark {
  color: #fff;
  background-color: #181c21e6;
}

.alert-dark-dark hr {
  border-top-color: #fff3;
}

.alert-dark-dark .close, .alert-dark-dark .alert-link {
  color: #fff;
}

.tooltip-inner {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.modal-open .tooltip {
  z-index: 1092;
}

.tooltip-secondary .tooltip-inner, .tooltip-secondary > .tooltip .tooltip-inner, .ngb-tooltip-secondary + ngb-tooltip-window .tooltip-inner {
  color: #fff;
  background: #8897aa;
}

.tooltip-secondary.bs-tooltip-top .arrow:before, .tooltip-secondary.bs-tooltip-auto[x-placement^="top"] .arrow:before, .tooltip-secondary > .tooltip.bs-tooltip-top .arrow:before, .tooltip-secondary > .tooltip.bs-tooltip-auto[x-placement^="top"] .arrow:before, .ngb-tooltip-secondary + ngb-tooltip-window.bs-tooltip-top .arrow:before, .ngb-tooltip-secondary + ngb-tooltip-window.bs-tooltip-auto[x-placement^="top"] .arrow:before {
  border-top-color: #8897aa;
}

.tooltip-secondary.bs-tooltip-right .arrow:before, .tooltip-secondary.bs-tooltip-auto[x-placement^="right"] .arrow:before, .tooltip-secondary > .tooltip.bs-tooltip-right .arrow:before, .tooltip-secondary > .tooltip.bs-tooltip-auto[x-placement^="right"] .arrow:before, .ngb-tooltip-secondary + ngb-tooltip-window.bs-tooltip-right .arrow:before, .ngb-tooltip-secondary + ngb-tooltip-window.bs-tooltip-auto[x-placement^="right"] .arrow:before {
  border-right-color: #8897aa;
}

.tooltip-secondary.bs-tooltip-bottom .arrow:before, .tooltip-secondary.bs-tooltip-auto[x-placement^="bottom"] .arrow:before, .tooltip-secondary > .tooltip.bs-tooltip-bottom .arrow:before, .tooltip-secondary > .tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow:before, .ngb-tooltip-secondary + ngb-tooltip-window.bs-tooltip-bottom .arrow:before, .ngb-tooltip-secondary + ngb-tooltip-window.bs-tooltip-auto[x-placement^="bottom"] .arrow:before {
  border-bottom-color: #8897aa;
}

.tooltip-secondary.bs-tooltip-left .arrow:before, .tooltip-secondary.bs-tooltip-auto[x-placement^="left"] .arrow:before, .tooltip-secondary > .tooltip.bs-tooltip-left .arrow:before, .tooltip-secondary > .tooltip.bs-tooltip-auto[x-placement^="left"] .arrow:before, .ngb-tooltip-secondary + ngb-tooltip-window.bs-tooltip-left .arrow:before, .ngb-tooltip-secondary + ngb-tooltip-window.bs-tooltip-auto[x-placement^="left"] .arrow:before {
  border-left-color: #8897aa;
}

.tooltip-success .tooltip-inner, .tooltip-success > .tooltip .tooltip-inner, .ngb-tooltip-success + ngb-tooltip-window .tooltip-inner {
  color: #fff;
  background: #02bc77;
}

.tooltip-success.bs-tooltip-top .arrow:before, .tooltip-success.bs-tooltip-auto[x-placement^="top"] .arrow:before, .tooltip-success > .tooltip.bs-tooltip-top .arrow:before, .tooltip-success > .tooltip.bs-tooltip-auto[x-placement^="top"] .arrow:before, .ngb-tooltip-success + ngb-tooltip-window.bs-tooltip-top .arrow:before, .ngb-tooltip-success + ngb-tooltip-window.bs-tooltip-auto[x-placement^="top"] .arrow:before {
  border-top-color: #02bc77;
}

.tooltip-success.bs-tooltip-right .arrow:before, .tooltip-success.bs-tooltip-auto[x-placement^="right"] .arrow:before, .tooltip-success > .tooltip.bs-tooltip-right .arrow:before, .tooltip-success > .tooltip.bs-tooltip-auto[x-placement^="right"] .arrow:before, .ngb-tooltip-success + ngb-tooltip-window.bs-tooltip-right .arrow:before, .ngb-tooltip-success + ngb-tooltip-window.bs-tooltip-auto[x-placement^="right"] .arrow:before {
  border-right-color: #02bc77;
}

.tooltip-success.bs-tooltip-bottom .arrow:before, .tooltip-success.bs-tooltip-auto[x-placement^="bottom"] .arrow:before, .tooltip-success > .tooltip.bs-tooltip-bottom .arrow:before, .tooltip-success > .tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow:before, .ngb-tooltip-success + ngb-tooltip-window.bs-tooltip-bottom .arrow:before, .ngb-tooltip-success + ngb-tooltip-window.bs-tooltip-auto[x-placement^="bottom"] .arrow:before {
  border-bottom-color: #02bc77;
}

.tooltip-success.bs-tooltip-left .arrow:before, .tooltip-success.bs-tooltip-auto[x-placement^="left"] .arrow:before, .tooltip-success > .tooltip.bs-tooltip-left .arrow:before, .tooltip-success > .tooltip.bs-tooltip-auto[x-placement^="left"] .arrow:before, .ngb-tooltip-success + ngb-tooltip-window.bs-tooltip-left .arrow:before, .ngb-tooltip-success + ngb-tooltip-window.bs-tooltip-auto[x-placement^="left"] .arrow:before {
  border-left-color: #02bc77;
}

.tooltip-info .tooltip-inner, .tooltip-info > .tooltip .tooltip-inner, .ngb-tooltip-info + ngb-tooltip-window .tooltip-inner {
  color: #fff;
  background: #28c3d7;
}

.tooltip-info.bs-tooltip-top .arrow:before, .tooltip-info.bs-tooltip-auto[x-placement^="top"] .arrow:before, .tooltip-info > .tooltip.bs-tooltip-top .arrow:before, .tooltip-info > .tooltip.bs-tooltip-auto[x-placement^="top"] .arrow:before, .ngb-tooltip-info + ngb-tooltip-window.bs-tooltip-top .arrow:before, .ngb-tooltip-info + ngb-tooltip-window.bs-tooltip-auto[x-placement^="top"] .arrow:before {
  border-top-color: #28c3d7;
}

.tooltip-info.bs-tooltip-right .arrow:before, .tooltip-info.bs-tooltip-auto[x-placement^="right"] .arrow:before, .tooltip-info > .tooltip.bs-tooltip-right .arrow:before, .tooltip-info > .tooltip.bs-tooltip-auto[x-placement^="right"] .arrow:before, .ngb-tooltip-info + ngb-tooltip-window.bs-tooltip-right .arrow:before, .ngb-tooltip-info + ngb-tooltip-window.bs-tooltip-auto[x-placement^="right"] .arrow:before {
  border-right-color: #28c3d7;
}

.tooltip-info.bs-tooltip-bottom .arrow:before, .tooltip-info.bs-tooltip-auto[x-placement^="bottom"] .arrow:before, .tooltip-info > .tooltip.bs-tooltip-bottom .arrow:before, .tooltip-info > .tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow:before, .ngb-tooltip-info + ngb-tooltip-window.bs-tooltip-bottom .arrow:before, .ngb-tooltip-info + ngb-tooltip-window.bs-tooltip-auto[x-placement^="bottom"] .arrow:before {
  border-bottom-color: #28c3d7;
}

.tooltip-info.bs-tooltip-left .arrow:before, .tooltip-info.bs-tooltip-auto[x-placement^="left"] .arrow:before, .tooltip-info > .tooltip.bs-tooltip-left .arrow:before, .tooltip-info > .tooltip.bs-tooltip-auto[x-placement^="left"] .arrow:before, .ngb-tooltip-info + ngb-tooltip-window.bs-tooltip-left .arrow:before, .ngb-tooltip-info + ngb-tooltip-window.bs-tooltip-auto[x-placement^="left"] .arrow:before {
  border-left-color: #28c3d7;
}

.tooltip-warning .tooltip-inner, .tooltip-warning > .tooltip .tooltip-inner, .ngb-tooltip-warning + ngb-tooltip-window .tooltip-inner {
  color: #665720;
  background: #ffd950;
}

.tooltip-warning.bs-tooltip-top .arrow:before, .tooltip-warning.bs-tooltip-auto[x-placement^="top"] .arrow:before, .tooltip-warning > .tooltip.bs-tooltip-top .arrow:before, .tooltip-warning > .tooltip.bs-tooltip-auto[x-placement^="top"] .arrow:before, .ngb-tooltip-warning + ngb-tooltip-window.bs-tooltip-top .arrow:before, .ngb-tooltip-warning + ngb-tooltip-window.bs-tooltip-auto[x-placement^="top"] .arrow:before {
  border-top-color: #ffd950;
}

.tooltip-warning.bs-tooltip-right .arrow:before, .tooltip-warning.bs-tooltip-auto[x-placement^="right"] .arrow:before, .tooltip-warning > .tooltip.bs-tooltip-right .arrow:before, .tooltip-warning > .tooltip.bs-tooltip-auto[x-placement^="right"] .arrow:before, .ngb-tooltip-warning + ngb-tooltip-window.bs-tooltip-right .arrow:before, .ngb-tooltip-warning + ngb-tooltip-window.bs-tooltip-auto[x-placement^="right"] .arrow:before {
  border-right-color: #ffd950;
}

.tooltip-warning.bs-tooltip-bottom .arrow:before, .tooltip-warning.bs-tooltip-auto[x-placement^="bottom"] .arrow:before, .tooltip-warning > .tooltip.bs-tooltip-bottom .arrow:before, .tooltip-warning > .tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow:before, .ngb-tooltip-warning + ngb-tooltip-window.bs-tooltip-bottom .arrow:before, .ngb-tooltip-warning + ngb-tooltip-window.bs-tooltip-auto[x-placement^="bottom"] .arrow:before {
  border-bottom-color: #ffd950;
}

.tooltip-warning.bs-tooltip-left .arrow:before, .tooltip-warning.bs-tooltip-auto[x-placement^="left"] .arrow:before, .tooltip-warning > .tooltip.bs-tooltip-left .arrow:before, .tooltip-warning > .tooltip.bs-tooltip-auto[x-placement^="left"] .arrow:before, .ngb-tooltip-warning + ngb-tooltip-window.bs-tooltip-left .arrow:before, .ngb-tooltip-warning + ngb-tooltip-window.bs-tooltip-auto[x-placement^="left"] .arrow:before {
  border-left-color: #ffd950;
}

.tooltip-danger .tooltip-inner, .tooltip-danger > .tooltip .tooltip-inner, .ngb-tooltip-danger + ngb-tooltip-window .tooltip-inner {
  color: #fff;
  background: #d9534f;
}

.tooltip-danger.bs-tooltip-top .arrow:before, .tooltip-danger.bs-tooltip-auto[x-placement^="top"] .arrow:before, .tooltip-danger > .tooltip.bs-tooltip-top .arrow:before, .tooltip-danger > .tooltip.bs-tooltip-auto[x-placement^="top"] .arrow:before, .ngb-tooltip-danger + ngb-tooltip-window.bs-tooltip-top .arrow:before, .ngb-tooltip-danger + ngb-tooltip-window.bs-tooltip-auto[x-placement^="top"] .arrow:before {
  border-top-color: #d9534f;
}

.tooltip-danger.bs-tooltip-right .arrow:before, .tooltip-danger.bs-tooltip-auto[x-placement^="right"] .arrow:before, .tooltip-danger > .tooltip.bs-tooltip-right .arrow:before, .tooltip-danger > .tooltip.bs-tooltip-auto[x-placement^="right"] .arrow:before, .ngb-tooltip-danger + ngb-tooltip-window.bs-tooltip-right .arrow:before, .ngb-tooltip-danger + ngb-tooltip-window.bs-tooltip-auto[x-placement^="right"] .arrow:before {
  border-right-color: #d9534f;
}

.tooltip-danger.bs-tooltip-bottom .arrow:before, .tooltip-danger.bs-tooltip-auto[x-placement^="bottom"] .arrow:before, .tooltip-danger > .tooltip.bs-tooltip-bottom .arrow:before, .tooltip-danger > .tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow:before, .ngb-tooltip-danger + ngb-tooltip-window.bs-tooltip-bottom .arrow:before, .ngb-tooltip-danger + ngb-tooltip-window.bs-tooltip-auto[x-placement^="bottom"] .arrow:before {
  border-bottom-color: #d9534f;
}

.tooltip-danger.bs-tooltip-left .arrow:before, .tooltip-danger.bs-tooltip-auto[x-placement^="left"] .arrow:before, .tooltip-danger > .tooltip.bs-tooltip-left .arrow:before, .tooltip-danger > .tooltip.bs-tooltip-auto[x-placement^="left"] .arrow:before, .ngb-tooltip-danger + ngb-tooltip-window.bs-tooltip-left .arrow:before, .ngb-tooltip-danger + ngb-tooltip-window.bs-tooltip-auto[x-placement^="left"] .arrow:before {
  border-left-color: #d9534f;
}

.tooltip-dark .tooltip-inner, .tooltip-dark > .tooltip .tooltip-inner, .ngb-tooltip-dark + ngb-tooltip-window .tooltip-inner {
  color: #fff;
  background: #2f3337;
}

.tooltip-dark.bs-tooltip-top .arrow:before, .tooltip-dark.bs-tooltip-auto[x-placement^="top"] .arrow:before, .tooltip-dark > .tooltip.bs-tooltip-top .arrow:before, .tooltip-dark > .tooltip.bs-tooltip-auto[x-placement^="top"] .arrow:before, .ngb-tooltip-dark + ngb-tooltip-window.bs-tooltip-top .arrow:before, .ngb-tooltip-dark + ngb-tooltip-window.bs-tooltip-auto[x-placement^="top"] .arrow:before {
  border-top-color: #2f3337;
}

.tooltip-dark.bs-tooltip-right .arrow:before, .tooltip-dark.bs-tooltip-auto[x-placement^="right"] .arrow:before, .tooltip-dark > .tooltip.bs-tooltip-right .arrow:before, .tooltip-dark > .tooltip.bs-tooltip-auto[x-placement^="right"] .arrow:before, .ngb-tooltip-dark + ngb-tooltip-window.bs-tooltip-right .arrow:before, .ngb-tooltip-dark + ngb-tooltip-window.bs-tooltip-auto[x-placement^="right"] .arrow:before {
  border-right-color: #2f3337;
}

.tooltip-dark.bs-tooltip-bottom .arrow:before, .tooltip-dark.bs-tooltip-auto[x-placement^="bottom"] .arrow:before, .tooltip-dark > .tooltip.bs-tooltip-bottom .arrow:before, .tooltip-dark > .tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow:before, .ngb-tooltip-dark + ngb-tooltip-window.bs-tooltip-bottom .arrow:before, .ngb-tooltip-dark + ngb-tooltip-window.bs-tooltip-auto[x-placement^="bottom"] .arrow:before {
  border-bottom-color: #2f3337;
}

.tooltip-dark.bs-tooltip-left .arrow:before, .tooltip-dark.bs-tooltip-auto[x-placement^="left"] .arrow:before, .tooltip-dark > .tooltip.bs-tooltip-left .arrow:before, .tooltip-dark > .tooltip.bs-tooltip-auto[x-placement^="left"] .arrow:before, .ngb-tooltip-dark + ngb-tooltip-window.bs-tooltip-left .arrow:before, .ngb-tooltip-dark + ngb-tooltip-window.bs-tooltip-auto[x-placement^="left"] .arrow:before {
  border-left-color: #2f3337;
}

.popover {
  box-shadow: 0 1px 6px #00000017;
}

.popover .arrow {
  z-index: 1;
}

.modal-open .popover {
  z-index: 1091;
}

.popover-secondary, .popover-secondary > .popover, .ngb-popover-secondary + ngb-popover-window {
  background: #8897aa;
  border-color: #0000;
}

.popover-secondary .popover-header, .popover-secondary > .popover .popover-header, .ngb-popover-secondary + ngb-popover-window .popover-header {
  color: #fff;
  background: none;
  border-color: #fff3;
}

.popover-secondary .popover-body, .popover-secondary > .popover .popover-body, .ngb-popover-secondary + ngb-popover-window .popover-body {
  color: #fff;
  background: none;
}

.popover-secondary .arrow:before, .popover-secondary > .popover .arrow:before, .ngb-popover-secondary + ngb-popover-window .arrow:before {
  border-color: #0000 !important;
}

.popover-secondary.bs-popover-top .arrow:after, .popover-secondary.bs-popover-auto[x-placement^="top"] .arrow:after, .popover-secondary > .popover.bs-popover-top .arrow:after, .popover-secondary > .popover.bs-popover-auto[x-placement^="top"] .arrow:after, .ngb-popover-secondary + ngb-popover-window.bs-popover-top .arrow:after, .ngb-popover-secondary + ngb-popover-window.bs-popover-auto[x-placement^="top"] .arrow:after {
  border-top-color: #8897aa;
}

.popover-secondary.bs-popover-right .arrow:after, .popover-secondary.bs-popover-auto[x-placement^="right"] .arrow:after, .popover-secondary > .popover.bs-popover-right .arrow:after, .popover-secondary > .popover.bs-popover-auto[x-placement^="right"] .arrow:after, .ngb-popover-secondary + ngb-popover-window.bs-popover-right .arrow:after, .ngb-popover-secondary + ngb-popover-window.bs-popover-auto[x-placement^="right"] .arrow:after {
  border-right-color: #8897aa;
}

.popover-secondary.bs-popover-bottom .arrow:after, .popover-secondary.bs-popover-auto[x-placement^="bottom"] .arrow:after, .popover-secondary > .popover.bs-popover-bottom .arrow:after, .popover-secondary > .popover.bs-popover-auto[x-placement^="bottom"] .arrow:after, .ngb-popover-secondary + ngb-popover-window.bs-popover-bottom .arrow:after, .ngb-popover-secondary + ngb-popover-window.bs-popover-auto[x-placement^="bottom"] .arrow:after {
  border-bottom-color: #8897aa;
}

.popover-secondary.bs-popover-left .arrow:after, .popover-secondary.bs-popover-auto[x-placement^="left"] .arrow:after, .popover-secondary > .popover.bs-popover-left .arrow:after, .popover-secondary > .popover.bs-popover-auto[x-placement^="left"] .arrow:after, .ngb-popover-secondary + ngb-popover-window.bs-popover-left .arrow:after, .ngb-popover-secondary + ngb-popover-window.bs-popover-auto[x-placement^="left"] .arrow:after {
  border-left-color: #8897aa;
}

.popover-success, .popover-success > .popover, .ngb-popover-success + ngb-popover-window {
  background: #02bc77;
  border-color: #0000;
}

.popover-success .popover-header, .popover-success > .popover .popover-header, .ngb-popover-success + ngb-popover-window .popover-header {
  color: #fff;
  background: none;
  border-color: #fff3;
}

.popover-success .popover-body, .popover-success > .popover .popover-body, .ngb-popover-success + ngb-popover-window .popover-body {
  color: #fff;
  background: none;
}

.popover-success .arrow:before, .popover-success > .popover .arrow:before, .ngb-popover-success + ngb-popover-window .arrow:before {
  border-color: #0000 !important;
}

.popover-success.bs-popover-top .arrow:after, .popover-success.bs-popover-auto[x-placement^="top"] .arrow:after, .popover-success > .popover.bs-popover-top .arrow:after, .popover-success > .popover.bs-popover-auto[x-placement^="top"] .arrow:after, .ngb-popover-success + ngb-popover-window.bs-popover-top .arrow:after, .ngb-popover-success + ngb-popover-window.bs-popover-auto[x-placement^="top"] .arrow:after {
  border-top-color: #02bc77;
}

.popover-success.bs-popover-right .arrow:after, .popover-success.bs-popover-auto[x-placement^="right"] .arrow:after, .popover-success > .popover.bs-popover-right .arrow:after, .popover-success > .popover.bs-popover-auto[x-placement^="right"] .arrow:after, .ngb-popover-success + ngb-popover-window.bs-popover-right .arrow:after, .ngb-popover-success + ngb-popover-window.bs-popover-auto[x-placement^="right"] .arrow:after {
  border-right-color: #02bc77;
}

.popover-success.bs-popover-bottom .arrow:after, .popover-success.bs-popover-auto[x-placement^="bottom"] .arrow:after, .popover-success > .popover.bs-popover-bottom .arrow:after, .popover-success > .popover.bs-popover-auto[x-placement^="bottom"] .arrow:after, .ngb-popover-success + ngb-popover-window.bs-popover-bottom .arrow:after, .ngb-popover-success + ngb-popover-window.bs-popover-auto[x-placement^="bottom"] .arrow:after {
  border-bottom-color: #02bc77;
}

.popover-success.bs-popover-left .arrow:after, .popover-success.bs-popover-auto[x-placement^="left"] .arrow:after, .popover-success > .popover.bs-popover-left .arrow:after, .popover-success > .popover.bs-popover-auto[x-placement^="left"] .arrow:after, .ngb-popover-success + ngb-popover-window.bs-popover-left .arrow:after, .ngb-popover-success + ngb-popover-window.bs-popover-auto[x-placement^="left"] .arrow:after {
  border-left-color: #02bc77;
}

.popover-info, .popover-info > .popover, .ngb-popover-info + ngb-popover-window {
  background: #28c3d7;
  border-color: #0000;
}

.popover-info .popover-header, .popover-info > .popover .popover-header, .ngb-popover-info + ngb-popover-window .popover-header {
  color: #fff;
  background: none;
  border-color: #fff3;
}

.popover-info .popover-body, .popover-info > .popover .popover-body, .ngb-popover-info + ngb-popover-window .popover-body {
  color: #fff;
  background: none;
}

.popover-info .arrow:before, .popover-info > .popover .arrow:before, .ngb-popover-info + ngb-popover-window .arrow:before {
  border-color: #0000 !important;
}

.popover-info.bs-popover-top .arrow:after, .popover-info.bs-popover-auto[x-placement^="top"] .arrow:after, .popover-info > .popover.bs-popover-top .arrow:after, .popover-info > .popover.bs-popover-auto[x-placement^="top"] .arrow:after, .ngb-popover-info + ngb-popover-window.bs-popover-top .arrow:after, .ngb-popover-info + ngb-popover-window.bs-popover-auto[x-placement^="top"] .arrow:after {
  border-top-color: #28c3d7;
}

.popover-info.bs-popover-right .arrow:after, .popover-info.bs-popover-auto[x-placement^="right"] .arrow:after, .popover-info > .popover.bs-popover-right .arrow:after, .popover-info > .popover.bs-popover-auto[x-placement^="right"] .arrow:after, .ngb-popover-info + ngb-popover-window.bs-popover-right .arrow:after, .ngb-popover-info + ngb-popover-window.bs-popover-auto[x-placement^="right"] .arrow:after {
  border-right-color: #28c3d7;
}

.popover-info.bs-popover-bottom .arrow:after, .popover-info.bs-popover-auto[x-placement^="bottom"] .arrow:after, .popover-info > .popover.bs-popover-bottom .arrow:after, .popover-info > .popover.bs-popover-auto[x-placement^="bottom"] .arrow:after, .ngb-popover-info + ngb-popover-window.bs-popover-bottom .arrow:after, .ngb-popover-info + ngb-popover-window.bs-popover-auto[x-placement^="bottom"] .arrow:after {
  border-bottom-color: #28c3d7;
}

.popover-info.bs-popover-left .arrow:after, .popover-info.bs-popover-auto[x-placement^="left"] .arrow:after, .popover-info > .popover.bs-popover-left .arrow:after, .popover-info > .popover.bs-popover-auto[x-placement^="left"] .arrow:after, .ngb-popover-info + ngb-popover-window.bs-popover-left .arrow:after, .ngb-popover-info + ngb-popover-window.bs-popover-auto[x-placement^="left"] .arrow:after {
  border-left-color: #28c3d7;
}

.popover-warning, .popover-warning > .popover, .ngb-popover-warning + ngb-popover-window {
  background: #ffd950;
  border-color: #0000;
}

.popover-warning .popover-header, .popover-warning > .popover .popover-header, .ngb-popover-warning + ngb-popover-window .popover-header {
  color: #665720;
  background: none;
  border-color: #66572033;
}

.popover-warning .popover-body, .popover-warning > .popover .popover-body, .ngb-popover-warning + ngb-popover-window .popover-body {
  color: #665720;
  background: none;
}

.popover-warning .arrow:before, .popover-warning > .popover .arrow:before, .ngb-popover-warning + ngb-popover-window .arrow:before {
  border-color: #0000 !important;
}

.popover-warning.bs-popover-top .arrow:after, .popover-warning.bs-popover-auto[x-placement^="top"] .arrow:after, .popover-warning > .popover.bs-popover-top .arrow:after, .popover-warning > .popover.bs-popover-auto[x-placement^="top"] .arrow:after, .ngb-popover-warning + ngb-popover-window.bs-popover-top .arrow:after, .ngb-popover-warning + ngb-popover-window.bs-popover-auto[x-placement^="top"] .arrow:after {
  border-top-color: #ffd950;
}

.popover-warning.bs-popover-right .arrow:after, .popover-warning.bs-popover-auto[x-placement^="right"] .arrow:after, .popover-warning > .popover.bs-popover-right .arrow:after, .popover-warning > .popover.bs-popover-auto[x-placement^="right"] .arrow:after, .ngb-popover-warning + ngb-popover-window.bs-popover-right .arrow:after, .ngb-popover-warning + ngb-popover-window.bs-popover-auto[x-placement^="right"] .arrow:after {
  border-right-color: #ffd950;
}

.popover-warning.bs-popover-bottom .arrow:after, .popover-warning.bs-popover-auto[x-placement^="bottom"] .arrow:after, .popover-warning > .popover.bs-popover-bottom .arrow:after, .popover-warning > .popover.bs-popover-auto[x-placement^="bottom"] .arrow:after, .ngb-popover-warning + ngb-popover-window.bs-popover-bottom .arrow:after, .ngb-popover-warning + ngb-popover-window.bs-popover-auto[x-placement^="bottom"] .arrow:after {
  border-bottom-color: #ffd950;
}

.popover-warning.bs-popover-left .arrow:after, .popover-warning.bs-popover-auto[x-placement^="left"] .arrow:after, .popover-warning > .popover.bs-popover-left .arrow:after, .popover-warning > .popover.bs-popover-auto[x-placement^="left"] .arrow:after, .ngb-popover-warning + ngb-popover-window.bs-popover-left .arrow:after, .ngb-popover-warning + ngb-popover-window.bs-popover-auto[x-placement^="left"] .arrow:after {
  border-left-color: #ffd950;
}

.popover-danger, .popover-danger > .popover, .ngb-popover-danger + ngb-popover-window {
  background: #d9534f;
  border-color: #0000;
}

.popover-danger .popover-header, .popover-danger > .popover .popover-header, .ngb-popover-danger + ngb-popover-window .popover-header {
  color: #fff;
  background: none;
  border-color: #fff3;
}

.popover-danger .popover-body, .popover-danger > .popover .popover-body, .ngb-popover-danger + ngb-popover-window .popover-body {
  color: #fff;
  background: none;
}

.popover-danger .arrow:before, .popover-danger > .popover .arrow:before, .ngb-popover-danger + ngb-popover-window .arrow:before {
  border-color: #0000 !important;
}

.popover-danger.bs-popover-top .arrow:after, .popover-danger.bs-popover-auto[x-placement^="top"] .arrow:after, .popover-danger > .popover.bs-popover-top .arrow:after, .popover-danger > .popover.bs-popover-auto[x-placement^="top"] .arrow:after, .ngb-popover-danger + ngb-popover-window.bs-popover-top .arrow:after, .ngb-popover-danger + ngb-popover-window.bs-popover-auto[x-placement^="top"] .arrow:after {
  border-top-color: #d9534f;
}

.popover-danger.bs-popover-right .arrow:after, .popover-danger.bs-popover-auto[x-placement^="right"] .arrow:after, .popover-danger > .popover.bs-popover-right .arrow:after, .popover-danger > .popover.bs-popover-auto[x-placement^="right"] .arrow:after, .ngb-popover-danger + ngb-popover-window.bs-popover-right .arrow:after, .ngb-popover-danger + ngb-popover-window.bs-popover-auto[x-placement^="right"] .arrow:after {
  border-right-color: #d9534f;
}

.popover-danger.bs-popover-bottom .arrow:after, .popover-danger.bs-popover-auto[x-placement^="bottom"] .arrow:after, .popover-danger > .popover.bs-popover-bottom .arrow:after, .popover-danger > .popover.bs-popover-auto[x-placement^="bottom"] .arrow:after, .ngb-popover-danger + ngb-popover-window.bs-popover-bottom .arrow:after, .ngb-popover-danger + ngb-popover-window.bs-popover-auto[x-placement^="bottom"] .arrow:after {
  border-bottom-color: #d9534f;
}

.popover-danger.bs-popover-left .arrow:after, .popover-danger.bs-popover-auto[x-placement^="left"] .arrow:after, .popover-danger > .popover.bs-popover-left .arrow:after, .popover-danger > .popover.bs-popover-auto[x-placement^="left"] .arrow:after, .ngb-popover-danger + ngb-popover-window.bs-popover-left .arrow:after, .ngb-popover-danger + ngb-popover-window.bs-popover-auto[x-placement^="left"] .arrow:after {
  border-left-color: #d9534f;
}

.popover-dark, .popover-dark > .popover, .ngb-popover-dark + ngb-popover-window {
  background: #2f3337;
  border-color: #0000;
}

.popover-dark .popover-header, .popover-dark > .popover .popover-header, .ngb-popover-dark + ngb-popover-window .popover-header {
  color: #fff;
  background: none;
  border-color: #fff3;
}

.popover-dark .popover-body, .popover-dark > .popover .popover-body, .ngb-popover-dark + ngb-popover-window .popover-body {
  color: #fff;
  background: none;
}

.popover-dark .arrow:before, .popover-dark > .popover .arrow:before, .ngb-popover-dark + ngb-popover-window .arrow:before {
  border-color: #0000 !important;
}

.popover-dark.bs-popover-top .arrow:after, .popover-dark.bs-popover-auto[x-placement^="top"] .arrow:after, .popover-dark > .popover.bs-popover-top .arrow:after, .popover-dark > .popover.bs-popover-auto[x-placement^="top"] .arrow:after, .ngb-popover-dark + ngb-popover-window.bs-popover-top .arrow:after, .ngb-popover-dark + ngb-popover-window.bs-popover-auto[x-placement^="top"] .arrow:after {
  border-top-color: #2f3337;
}

.popover-dark.bs-popover-right .arrow:after, .popover-dark.bs-popover-auto[x-placement^="right"] .arrow:after, .popover-dark > .popover.bs-popover-right .arrow:after, .popover-dark > .popover.bs-popover-auto[x-placement^="right"] .arrow:after, .ngb-popover-dark + ngb-popover-window.bs-popover-right .arrow:after, .ngb-popover-dark + ngb-popover-window.bs-popover-auto[x-placement^="right"] .arrow:after {
  border-right-color: #2f3337;
}

.popover-dark.bs-popover-bottom .arrow:after, .popover-dark.bs-popover-auto[x-placement^="bottom"] .arrow:after, .popover-dark > .popover.bs-popover-bottom .arrow:after, .popover-dark > .popover.bs-popover-auto[x-placement^="bottom"] .arrow:after, .ngb-popover-dark + ngb-popover-window.bs-popover-bottom .arrow:after, .ngb-popover-dark + ngb-popover-window.bs-popover-auto[x-placement^="bottom"] .arrow:after {
  border-bottom-color: #2f3337;
}

.popover-dark.bs-popover-left .arrow:after, .popover-dark.bs-popover-auto[x-placement^="left"] .arrow:after, .popover-dark > .popover.bs-popover-left .arrow:after, .popover-dark > .popover.bs-popover-auto[x-placement^="left"] .arrow:after, .ngb-popover-dark + ngb-popover-window.bs-popover-left .arrow:after, .ngb-popover-dark + ngb-popover-window.bs-popover-auto[x-placement^="left"] .arrow:after {
  border-left-color: #2f3337;
}

.form-control {
  min-height: calc(2.25rem + 2px);
}

.form-control-sm, .input-group-sm .form-control {
  min-height: calc(1.5rem + 2px);
}

.form-control-lg, .input-group-lg .form-control {
  min-height: calc(3rem + 2px);
}

select.form-control[size="0"]:not([multiple]) {
  height: calc(2.25rem + 2px);
}

select.form-control-sm[size="0"]:not([multiple]) {
  height: calc(1.5rem + 2px);
}

select.form-control-lg[size="0"]:not([multiple]) {
  height: calc(3rem + 2px);
}

.form-control-plaintext {
  color: #4e5155;
}

.form-inline .form-control-plaintext {
  width: auto;
}

.form-control-inverted {
  border-color: #ffffff4d;
}

.form-control-inverted, .form-control-inverted:hover, .form-control-inverted:focus {
  color: #fff;
  background-color: #ffffff4d;
}

.form-control-inverted::-webkit-input-placeholder {
  color: #ffffffb3;
}

.form-control-inverted:-ms-input-placeholder {
  color: #ffffffb3;
}

.form-control-inverted::placeholder {
  color: #ffffffb3;
}

.form-control-inverted:disabled {
  color: #fff !important;
  background-color: #ffffff1a !important;
  border-color: #ffffff1a !important;
}

.form-control-inverted option {
  color: #4e5155 !important;
}

.form-label, .col-form-label, .col-form-legend {
  margin-bottom: calc(.438rem - 2px);
  font-size: .83125rem;
  font-weight: 500;
}

.col-form-label, .col-form-legend {
  padding-bottom: 0;
  line-height: 1.54;
}

.form-label-sm, .col-form-label-sm, .col-form-legend-sm {
  line-height: 1.5;
  font-size: .75rem !important;
}

.form-label-lg, .col-form-label-lg, .col-form-legend-lg {
  line-height: 1.5;
  font-size: 1rem !important;
}

.form-check {
  min-height: 1.31418rem;
}

.valid-feedback {
  color: #02bc77;
  margin-top: .25rem;
  display: none;
}

.valid-tooltip {
  z-index: 5;
  width: 250px;
  color: #fff;
  background-color: #02bc77cc;
  border-radius: .2rem;
  margin-top: .1rem;
  padding: .5rem;
  line-height: 1;
  display: none;
  position: absolute;
  top: 100%;
}

.was-validated .form-control:valid, .form-control.is-valid, .was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #02bc77 !important;
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #02bc77 !important;
  box-shadow: 0 0 0 2px #02bc7740 !important;
}

.was-validated .form-control:valid ~ .valid-feedback, .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback, .form-control.is-valid ~ .valid-tooltip, .was-validated .custom-select:valid ~ .valid-feedback, .was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback, .custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control.custom-checkbox .custom-control-input:valid ~ .custom-control-label, .custom-control.custom-checkbox .custom-control-input.is-valid ~ .custom-control-label, .was-validated .custom-control.custom-radio .custom-control-input:valid ~ .custom-control-label, .custom-control.custom-radio .custom-control-input.is-valid ~ .custom-control-label {
  color: #02bc77;
}

.was-validated .custom-control.custom-checkbox .custom-control-input:valid ~ .custom-control-label:before, .was-validated .custom-control.custom-checkbox .custom-control-input:valid:focus ~ .custom-control-label:before, .was-validated .custom-control.custom-checkbox .custom-control-input:valid:active ~ .custom-control-label:before, .custom-control.custom-checkbox .custom-control-input.is-valid ~ .custom-control-label:before, .custom-control.custom-checkbox .custom-control-input.is-valid:focus ~ .custom-control-label:before, .custom-control.custom-checkbox .custom-control-input.is-valid:active ~ .custom-control-label:before, .was-validated .custom-control.custom-radio .custom-control-input:valid ~ .custom-control-label:before, .was-validated .custom-control.custom-radio .custom-control-input:valid:focus ~ .custom-control-label:before, .was-validated .custom-control.custom-radio .custom-control-input:valid:active ~ .custom-control-label:before, .custom-control.custom-radio .custom-control-input.is-valid ~ .custom-control-label:before, .custom-control.custom-radio .custom-control-input.is-valid:focus ~ .custom-control-label:before, .custom-control.custom-radio .custom-control-input.is-valid:active ~ .custom-control-label:before {
  border-color: #02bc77;
}

.was-validated .custom-control.custom-checkbox .custom-control-input:valid:focus ~ .custom-control-label:before, .custom-control.custom-checkbox .custom-control-input.is-valid:focus ~ .custom-control-label:before, .was-validated .custom-control.custom-radio .custom-control-input:valid:focus ~ .custom-control-label:before, .custom-control.custom-radio .custom-control-input.is-valid:focus ~ .custom-control-label:before {
  box-shadow: 0 0 0 2px #02bc7766;
}

.was-validated .custom-control.custom-checkbox .custom-control-input:valid:checked ~ .custom-control-label:before, .custom-control.custom-checkbox .custom-control-input.is-valid:checked ~ .custom-control-label:before, .was-validated .custom-control.custom-radio .custom-control-input:valid:checked ~ .custom-control-label:before, .custom-control.custom-radio .custom-control-input.is-valid:checked ~ .custom-control-label:before {
  background-color: #02bc77;
  border-color: #02bc77;
}

.was-validated .custom-control.custom-checkbox .custom-control-input:valid ~ .valid-feedback, .was-validated .custom-control.custom-checkbox .custom-control-input:valid ~ .valid-tooltip, .custom-control.custom-checkbox .custom-control-input.is-valid ~ .valid-feedback, .custom-control.custom-checkbox .custom-control-input.is-valid ~ .valid-tooltip, .was-validated .custom-control.custom-radio .custom-control-input:valid ~ .valid-feedback, .was-validated .custom-control.custom-radio .custom-control-input:valid ~ .valid-tooltip, .custom-control.custom-radio .custom-control-input.is-valid ~ .valid-feedback, .custom-control.custom-radio .custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control.custom-checkbox .custom-control-input:valid:checked ~ .custom-control-label:before, .custom-control.custom-checkbox .custom-control-input.is-valid:checked ~ .custom-control-label:before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.5 7.5'%3E%3Cpolyline points='0.75 4.35 4.18 6.75 8.75 0.75' style='fill:none;stroke:%23fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px'/%3E%3C/svg%3E");
}

.was-validated .custom-control.custom-radio .custom-control-input:valid:checked ~ .custom-control-label:before, .custom-control.custom-radio .custom-control-input.is-valid:checked ~ .custom-control-label:before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'%3E%3Ccircle cx='5' cy='5' r='4.5' style='fill:%23fff'/%3E%3Cpath d='M5,1A4,4,0,1,1,1,5,4,4,0,0,1,5,1M5,0a5,5,0,1,0,5,5A5,5,0,0,0,5,0Z' style='fill:%23fff'/%3E%3C/svg%3E");
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #02bc77 !important;
}

.was-validated .custom-file-input:valid ~ .custom-file-label:after, .custom-file-input.is-valid ~ .custom-file-label:after {
  border-color: inherit !important;
}

.was-validated .custom-file-input:valid:focus, .custom-file-input.is-valid:focus {
  box-shadow: 0 0 0 2px #02bc774d !important;
}

.invalid-feedback {
  color: #d9534f;
  margin-top: .25rem;
  display: none;
}

.invalid-tooltip {
  z-index: 5;
  width: 250px;
  color: #fff;
  background-color: #d9534fcc;
  border-radius: .2rem;
  margin-top: .1rem;
  padding: .5rem;
  line-height: 1;
  display: none;
  position: absolute;
  top: 100%;
}

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #d9534f !important;
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #d9534f !important;
  box-shadow: 0 0 0 2px #d9534f40 !important;
}

.was-validated .form-control:invalid ~ .invalid-feedback, .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback, .form-control.is-invalid ~ .invalid-tooltip, .was-validated .custom-select:invalid ~ .invalid-feedback, .was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback, .custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control.custom-checkbox .custom-control-input:invalid ~ .custom-control-label, .custom-control.custom-checkbox .custom-control-input.is-invalid ~ .custom-control-label, .was-validated .custom-control.custom-radio .custom-control-input:invalid ~ .custom-control-label, .custom-control.custom-radio .custom-control-input.is-invalid ~ .custom-control-label {
  color: #d9534f;
}

.was-validated .custom-control.custom-checkbox .custom-control-input:invalid ~ .custom-control-label:before, .was-validated .custom-control.custom-checkbox .custom-control-input:invalid:focus ~ .custom-control-label:before, .was-validated .custom-control.custom-checkbox .custom-control-input:invalid:active ~ .custom-control-label:before, .custom-control.custom-checkbox .custom-control-input.is-invalid ~ .custom-control-label:before, .custom-control.custom-checkbox .custom-control-input.is-invalid:focus ~ .custom-control-label:before, .custom-control.custom-checkbox .custom-control-input.is-invalid:active ~ .custom-control-label:before, .was-validated .custom-control.custom-radio .custom-control-input:invalid ~ .custom-control-label:before, .was-validated .custom-control.custom-radio .custom-control-input:invalid:focus ~ .custom-control-label:before, .was-validated .custom-control.custom-radio .custom-control-input:invalid:active ~ .custom-control-label:before, .custom-control.custom-radio .custom-control-input.is-invalid ~ .custom-control-label:before, .custom-control.custom-radio .custom-control-input.is-invalid:focus ~ .custom-control-label:before, .custom-control.custom-radio .custom-control-input.is-invalid:active ~ .custom-control-label:before {
  border-color: #d9534f;
}

.was-validated .custom-control.custom-checkbox .custom-control-input:invalid:focus ~ .custom-control-label:before, .custom-control.custom-checkbox .custom-control-input.is-invalid:focus ~ .custom-control-label:before, .was-validated .custom-control.custom-radio .custom-control-input:invalid:focus ~ .custom-control-label:before, .custom-control.custom-radio .custom-control-input.is-invalid:focus ~ .custom-control-label:before {
  box-shadow: 0 0 0 2px #d9534f66;
}

.was-validated .custom-control.custom-checkbox .custom-control-input:invalid:checked ~ .custom-control-label:before, .custom-control.custom-checkbox .custom-control-input.is-invalid:checked ~ .custom-control-label:before, .was-validated .custom-control.custom-radio .custom-control-input:invalid:checked ~ .custom-control-label:before, .custom-control.custom-radio .custom-control-input.is-invalid:checked ~ .custom-control-label:before {
  background-color: #d9534f;
  border-color: #d9534f;
}

.was-validated .custom-control.custom-checkbox .custom-control-input:invalid ~ .invalid-feedback, .was-validated .custom-control.custom-checkbox .custom-control-input:invalid ~ .invalid-tooltip, .custom-control.custom-checkbox .custom-control-input.is-invalid ~ .invalid-feedback, .custom-control.custom-checkbox .custom-control-input.is-invalid ~ .invalid-tooltip, .was-validated .custom-control.custom-radio .custom-control-input:invalid ~ .invalid-feedback, .was-validated .custom-control.custom-radio .custom-control-input:invalid ~ .invalid-tooltip, .custom-control.custom-radio .custom-control-input.is-invalid ~ .invalid-feedback, .custom-control.custom-radio .custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control.custom-checkbox .custom-control-input:invalid:checked ~ .custom-control-label:before, .custom-control.custom-checkbox .custom-control-input.is-invalid:checked ~ .custom-control-label:before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.5 7.5'%3E%3Cpolyline points='0.75 4.35 4.18 6.75 8.75 0.75' style='fill:none;stroke:%23fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px'/%3E%3C/svg%3E");
}

.was-validated .custom-control.custom-radio .custom-control-input:invalid:checked ~ .custom-control-label:before, .custom-control.custom-radio .custom-control-input.is-invalid:checked ~ .custom-control-label:before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'%3E%3Ccircle cx='5' cy='5' r='4.5' style='fill:%23fff'/%3E%3Cpath d='M5,1A4,4,0,1,1,1,5,4,4,0,0,1,5,1M5,0a5,5,0,1,0,5,5A5,5,0,0,0,5,0Z' style='fill:%23fff'/%3E%3C/svg%3E");
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #d9534f !important;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label:after, .custom-file-input.is-invalid ~ .custom-file-label:after {
  border-color: inherit !important;
}

.was-validated .custom-file-input:invalid:focus, .custom-file-input.is-invalid:focus {
  box-shadow: 0 0 0 2px #d9534f4d !important;
}

.form-inline .custom-control {
  width: 100%;
  display: block;
}

@media (min-width: 576px) {
  .form-inline .custom-control, .form-inline .custom-select, .form-inline .form-control-plaintext {
    width: auto;
    display: inline-block;
  }

  .form-inline .form-text {
    margin-top: 0;
  }

  .form-inline .form-label {
    margin-bottom: 0 !important;
  }
}

.custom-control {
  min-height: 1.125rem;
  position: relative;
}

.custom-control-label {
  position: static;
}

.custom-control-label:before {
  pointer-events: auto;
  background-color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid #181c211a;
  transition: all .2s;
  top: .0625rem;
  left: 0;
}

.custom-control-label:after {
  display: none;
}

.custom-checkbox .custom-control-label:before {
  background-size: 65% 65%;
}

.custom-radio .custom-control-label:before {
  background-size: 40% 40%;
}

.custom-control-input:active ~ .custom-control-label:before {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.custom-control-input:active:not(:checked) ~ .custom-control-label:before {
  background-color: #fff;
}

.custom-control-input:disabled ~ .custom-control-label:before, fieldset[disabled] .custom-control-input ~ .custom-control-label:before {
  background-color: #f1f1f2 !important;
  border-color: #181c211a !important;
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label:before, fieldset[disabled] .custom-control-input:checked ~ .custom-control-label:before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.5 7.5'%3E%3Cpolyline points='0.75 4.35 4.18 6.75 8.75 0.75' style='fill:none;stroke:%23a3a4a6;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px'/%3E%3C/svg%3E") !important;
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label:before, fieldset[disabled] .custom-control-input:checked ~ .custom-control-label:before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'%3E%3Ccircle cx='5' cy='5' r='4.5' style='fill:%23a3a4a6'/%3E%3Cpath d='M5,1A4,4,0,1,1,1,5,4,4,0,0,1,5,1M5,0a5,5,0,1,0,5,5A5,5,0,0,0,5,0Z' style='fill:%23a3a4a6'/%3E%3C/svg%3E") !important;
}

.custom-controls-stacked .custom-control {
  margin-bottom: .5rem;
  display: block;
}

.custom-select {
  background-clip: padding-box;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
  display: block;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAMCAYAAACEJVa/AAAABGdBTUEAALGPC/xhBQAAAF5JREFUKBVjePb8xX8GCgEThfrB2gePISzEeMfDx6/h/79/9djUMjIxNRLlnR1bNjWAFKMbAhIDyRFlCEgzukEwA8AGkxrFIK+BMFgzjCDVEJg+ZJpo7yBrQmcPHkMAR5Yf55H9JN4AAAAASUVORK5CYII=") !important;
}

.custom-select[size="0"]:not([multiple]) {
  padding-right: 2.75rem;
}

.custom-select-inverted {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAMCAYAAACEJVa/AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAEpJREFUeNpi+P//vwMQM1CCGYaXISwMxIEKIA7BIr4GiDuYiDSkC4gPoYkdgoqT5B0OIF4AxGegNAe5YSIIxJOg9HCNYqoYAhBgAKHWBg+hRrNxAAAAAElFTkSuQmCC") !important;
}

.custom-select-inverted, .custom-select-inverted:focus {
  color: #fff;
  background-color: #ffffff4d;
  border-color: #0000;
}

.custom-select-inverted:disabled {
  color: #fff !important;
  background-color: #ffffff1a !important;
}

.custom-select-inverted::-webkit-input-placeholder {
  color: #ffffffb3;
}

.custom-select-inverted:-ms-input-placeholder {
  color: #ffffffb3;
}

.custom-select-inverted::placeholder {
  color: #ffffffb3;
}

.custom-select-inverted option {
  color: #4e5155 !important;
}

.custom-select-sm, .input-group-sm .custom-select {
  height: calc(1.5rem + 2px);
  background-position: right .625rem center;
  padding: .188rem 2.5rem .188rem .625rem;
  font-size: .75rem;
  line-height: 1.5;
}

.custom-select-sm[size="0"]:not([multiple]), .input-group-sm .custom-select[size="0"]:not([multiple]) {
  padding-right: 2.5rem;
}

.custom-select-lg, .input-group-lg .custom-select {
  height: calc(3rem + 2px);
  background-position: right 1.25rem center;
  padding: .75rem 3.125rem .75rem 1.25rem;
  font-size: 1rem;
  line-height: 1.5;
}

.custom-select-lg[size="0"]:not([multiple]), .input-group-lg .custom-select[size="0"]:not([multiple]) {
  padding-right: 3.125rem;
}

.custom-select[class][multiple], .custom-select[size]:not([size="1"]):not([size="0"]) {
  height: auto !important;
  background-image: none !important;
  padding-left: .875rem !important;
  padding-right: .875rem !important;
}

.custom-select-sm[class][multiple], .custom-select-sm[size]:not([size="1"]):not([size="0"]), .input-group-sm .custom-select[class][multiple], .input-group-sm .custom-select[size]:not([size="1"]):not([size="0"]) {
  padding-left: .625rem !important;
  padding-right: .625rem !important;
}

.custom-select-lg[class][multiple], .custom-select-lg[size]:not([size="1"]):not([size="0"]), .input-group-lg .custom-select[class][multiple], .input-group-lg .custom-select[size]:not([size="1"]):not([size="0"]) {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}

.custom-file {
  width: 100%;
  display: block;
}

.custom-file-label {
  background-clip: padding-box;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
}

.custom-file-input:disabled ~ .custom-file-label {
  background: #f1f1f2;
}

.custom-file-input:disabled ~ .custom-file-label:before {
  opacity: .65;
}

.switcher {
  vertical-align: middle;
  min-height: 1.375rem;
  border-radius: 60rem;
  margin-bottom: 0;
  margin-right: .75rem;
  padding-left: 2.375rem;
  font-size: .894rem;
  font-weight: normal;
  line-height: 1.4;
  display: inline-block;
  position: relative;
}

.switcher .switcher-blank .switcher-indicator {
  top: 0 !important;
}

.switcher .switcher-indicator {
  width: 2.375rem;
  height: 1.375rem;
  font-size: .625rem;
  line-height: 1.375rem;
  top: 0;
}

.switcher .switcher-label {
  top: .0617rem;
}

.switcher .switcher-input:checked ~ .switcher-indicator:after {
  left: 1rem;
}

.switcher .switcher-indicator:after {
  width: .875rem;
  height: .875rem;
  margin: .25rem 0 0 .25rem;
  top: 0;
}

.switcher .switcher-no {
  padding-left: 1.125rem;
  padding-right: .25rem;
}

.switcher .switcher-yes {
  padding-left: .25rem;
  padding-right: 1.125rem;
}

@media (min-width: 576px) {
  .form-inline .switcher {
    margin-right: 0;
    display: block;
  }
}

.switcher-input {
  z-index: -1;
  opacity: 0;
  margin: 0;
  padding: 0;
  position: absolute;
}

.switcher-indicator {
  color: #181c214d;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  background: #181c211a;
  border-radius: 60rem;
  font-weight: bold;
  transition-property: left, right, background, box-shadow, -webkit-box-shadow;
  transition-duration: .2s;
  position: absolute;
  left: 0;
  overflow: hidden;
}

.switcher-label {
  color: #4e5155;
  cursor: default;
  padding-left: .5rem;
  font-weight: 400;
  display: inline-block;
  position: relative;
}

.switcher-no, .switcher-yes {
  width: 100%;
  height: 100%;
  text-align: center;
  transition-property: left, right;
  transition-duration: .2s;
  position: absolute;
  top: 0;
}

.switcher-no {
  left: 0;
}

.switcher-yes {
  left: -100%;
}

.switcher-input:not(:checked) ~ .switcher-indicator .switcher-yes {
  color: #0000 !important;
}

.switcher-input:checked ~ .switcher-indicator .switcher-no {
  left: 100%;
  color: #0000 !important;
}

.switcher-input:checked ~ .switcher-indicator .switcher-yes {
  left: 0;
}

.switcher-indicator:after {
  content: "";
  background: #fff;
  border-radius: 999px;
  transition-property: left, right, background;
  transition-duration: .2s;
  display: block;
  position: absolute;
  left: 0;
  box-shadow: 0 0 0 1px #181c210a, 0 1px 6px #181c2117;
}

.switcher-input:disabled ~ .switcher-indicator, fieldset[disabled] .switcher-input ~ .switcher-indicator {
  opacity: .5;
  cursor: not-allowed !important;
}

.switcher-input:disabled ~ .switcher-indicator:after, fieldset[disabled] .switcher-input ~ .switcher-indicator:after {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.switcher-input:disabled ~ .switcher-label, fieldset[disabled] .switcher-input ~ .switcher-label {
  cursor: not-allowed;
  color: #a3a4a6 !important;
}

.switchers-stacked .switcher {
  margin-bottom: .5rem;
  margin-right: 0;
  display: block;
}

.switcher-square, .switcher-square .switcher-indicator {
  border-radius: .25rem;
}

.switcher-square .switcher-indicator:after {
  border-radius: calc(.25rem - 2px);
}

.switcher-sm, .form-group-sm .switcher {
  min-height: 1.125rem;
  padding-left: 2rem;
  font-size: .75rem;
  line-height: 1.486;
}

.switcher-sm .switcher-blank .switcher-indicator, .form-group-sm .switcher .switcher-blank .switcher-indicator {
  top: 0 !important;
}

.switcher-sm .switcher-indicator, .form-group-sm .switcher .switcher-indicator {
  width: 2rem;
  height: 1.125rem;
  font-size: .625rem;
  line-height: 1.125rem;
  top: 0;
}

.switcher-sm .switcher-label, .form-group-sm .switcher .switcher-label {
  top: .00525rem;
}

.switcher-sm .switcher-input:checked ~ .switcher-indicator:after, .form-group-sm .switcher .switcher-input:checked ~ .switcher-indicator:after {
  left: .875rem;
}

.switcher-sm .switcher-indicator:after, .form-group-sm .switcher .switcher-indicator:after {
  width: .625rem;
  height: .625rem;
  margin: .25rem 0 0 .25rem;
  top: 0;
}

.switcher-sm .switcher-no, .form-group-sm .switcher .switcher-no {
  padding-left: .875rem;
  padding-right: .25rem;
}

.switcher-sm .switcher-yes, .form-group-sm .switcher .switcher-yes {
  padding-left: .25rem;
  padding-right: .875rem;
}

.switcher-lg, .form-group-lg .switcher {
  min-height: 1.75rem;
  padding-left: 3.25rem;
  font-size: 1rem;
  line-height: 1.47;
}

.switcher-lg .switcher-blank .switcher-indicator, .form-group-lg .switcher .switcher-blank .switcher-indicator {
  top: 0 !important;
}

.switcher-lg .switcher-indicator, .form-group-lg .switcher .switcher-indicator {
  width: 3.25rem;
  height: 1.75rem;
  font-size: .625rem;
  line-height: 1.75rem;
  top: 0;
}

.switcher-lg .switcher-label, .form-group-lg .switcher .switcher-label {
  top: .14rem;
}

.switcher-lg .switcher-input:checked ~ .switcher-indicator:after, .form-group-lg .switcher .switcher-input:checked ~ .switcher-indicator:after {
  left: 1.5rem;
}

.switcher-lg .switcher-indicator:after, .form-group-lg .switcher .switcher-indicator:after {
  width: 1.25rem;
  height: 1.25rem;
  margin: .25rem 0 0 .25rem;
  top: 0;
}

.switcher-lg .switcher-no, .form-group-lg .switcher .switcher-no {
  padding-left: 1.5rem;
  padding-right: .25rem;
}

.switcher-lg .switcher-yes, .form-group-lg .switcher .switcher-yes {
  padding-left: .25rem;
  padding-right: 1.5rem;
}

.switcher-secondary.switcher .switcher-input:checked ~ .switcher-indicator {
  color: #fff;
  background: #8897aa;
}

.switcher-secondary.switcher .switcher-input:focus ~ .switcher-indicator {
  box-shadow: 0 0 0 2px #8897aa66;
}

.switcher-secondary.switcher .switcher-input:active ~ .switcher-indicator {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.switcher-success.switcher .switcher-input:checked ~ .switcher-indicator {
  color: #fff;
  background: #02bc77;
}

.switcher-success.switcher .switcher-input:focus ~ .switcher-indicator {
  box-shadow: 0 0 0 2px #02bc7766;
}

.switcher-success.switcher .switcher-input:active ~ .switcher-indicator {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.switcher-info.switcher .switcher-input:checked ~ .switcher-indicator {
  color: #fff;
  background: #28c3d7;
}

.switcher-info.switcher .switcher-input:focus ~ .switcher-indicator {
  box-shadow: 0 0 0 2px #28c3d766;
}

.switcher-info.switcher .switcher-input:active ~ .switcher-indicator {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.switcher-warning.switcher .switcher-input:checked ~ .switcher-indicator {
  color: #665720;
  background: #ffd950;
}

.switcher-warning.switcher .switcher-input:focus ~ .switcher-indicator {
  box-shadow: 0 0 0 2px #ffd95066;
}

.switcher-warning.switcher .switcher-input:active ~ .switcher-indicator {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.switcher-danger.switcher .switcher-input:checked ~ .switcher-indicator {
  color: #fff;
  background: #d9534f;
}

.switcher-danger.switcher .switcher-input:focus ~ .switcher-indicator {
  box-shadow: 0 0 0 2px #d9534f66;
}

.switcher-danger.switcher .switcher-input:active ~ .switcher-indicator {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.switcher-dark.switcher .switcher-input:checked ~ .switcher-indicator {
  color: #fff;
  background: #181c21e6;
}

.switcher-dark.switcher .switcher-input:focus ~ .switcher-indicator {
  box-shadow: 0 0 0 2px #181c2166;
}

.switcher-dark.switcher .switcher-input:active ~ .switcher-indicator {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.was-validated .switcher-input:valid:checked ~ .switcher-indicator, .switcher-input.is-valid:checked ~ .switcher-indicator {
  color: #fff !important;
  background: #02bc77 !important;
}

.was-validated .switcher-input:valid:focus ~ .switcher-indicator, .switcher-input.is-valid:focus ~ .switcher-indicator {
  box-shadow: 0 0 0 2px #02bc7766 !important;
}

.was-validated .switcher-input:valid:active ~ .switcher-indicator, .switcher-input.is-valid:active ~ .switcher-indicator {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.was-validated .switcher-input:valid ~ .switcher-label, .switcher-input.is-valid ~ .switcher-label {
  color: #02bc77 !important;
}

.was-validated .switcher-input:invalid:checked ~ .switcher-indicator, .switcher-input.is-invalid:checked ~ .switcher-indicator {
  color: #fff !important;
  background: #d9534f !important;
}

.was-validated .switcher-input:invalid:focus ~ .switcher-indicator, .switcher-input.is-invalid:focus ~ .switcher-indicator {
  box-shadow: 0 0 0 2px #d9534f66 !important;
}

.was-validated .switcher-input:invalid:active ~ .switcher-indicator, .switcher-input.is-invalid:active ~ .switcher-indicator {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.was-validated .switcher-input:invalid ~ .switcher-label, .switcher-input.is-invalid ~ .switcher-label {
  color: #d9534f !important;
}

.input-group .custom-select {
  width: auto;
  flex: 0 auto;
}

.input-group-text {
  background-clip: padding-box;
}

.modal {
  z-index: 1090;
}

.modal-backdrop {
  z-index: 1089;
}

.modal-content {
  box-shadow: 0 1px 6px #00000017;
}

.modal-header {
  padding: 1.25rem 2.5625rem 1.25rem 1.5625rem;
  position: relative;
}

.modal-footer {
  padding: 1.25rem 1.5625rem;
}

.modal-header .close, .modal-slide .close {
  margin: 0;
  padding: 0;
  line-height: 1.47;
  position: absolute;
  top: 50%;
  right: 1.5625rem;
  transform: translate(0, -50%);
}

@media (min-width: 576px) {
  .modal-content {
    box-shadow: 0 1px 6px #00000017;
  }

  ngb-modal-window.modal-sm {
    max-width: none;
  }

  .modal-sm .modal-dialog {
    max-width: 22.5rem;
  }
}

@media (min-width: 992px) {
  ngb-modal-window.modal-lg {
    max-width: none;
  }

  .modal-lg .modal-dialog {
    max-width: 50rem;
  }
}

.modal-top .modal-dialog {
  margin-top: 0;
}

.modal-top .modal-content {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.modal-slide, .modal-slide .modal {
  padding: 0 !important;
  overflow: hidden !important;
}

.modal-slide .modal-dialog {
  max-width: none;
  width: 100%;
  margin: 0;
  position: fixed;
  inset: 0 0 0 auto;
}

@media (min-width: 576px) {
  .modal-slide .modal-dialog {
    width: 20rem;
  }
}

.modal-slide .modal-content {
  height: 100%;
  border-radius: 0;
  padding-top: 3.22127rem;
  padding-bottom: 3.22127rem;
  overflow: auto;
}

.modal-slide .modal-body {
  flex-grow: 0;
  margin: auto 0;
  padding-top: 0;
  padding-bottom: 0;
}

.modal-slide .close {
  z-index: 10;
  top: .625rem;
  transform: none;
}

.modal-fill-in .modal-dialog {
  min-height: 100vh;
  margin: 0 auto;
  padding-top: 3.22127rem;
  padding-bottom: 3.22127rem;
  display: flex;
}

.modal-fill-in .modal-content {
  width: 100%;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: none;
  border: 0;
  margin: auto;
}

.modal-fill-in .close {
  font-size: 2rem;
  position: absolute;
  top: -2rem;
  right: 1.5625rem;
  transform: none;
}

.modal.fade .modal-dialog {
  transform: translateY(150px)scale(.8);
}

.modal.show .modal-dialog {
  transform: translateY(0)scale(1);
}

.modal-top.fade .modal-dialog, .modal-top .modal.fade .modal-dialog {
  transform: translateY(-100%);
}

.modal-top.show .modal-dialog, .modal-top .modal.show .modal-dialog {
  transform: translateY(0);
}

.modal-slide.fade .modal-dialog, .modal-slide .modal.fade .modal-dialog {
  transform: translateX(100%);
}

.modal-slide.show .modal-dialog, .modal-slide .modal.show .modal-dialog {
  transform: translateX(0) !important;
}

.modal-fill-in.fade .modal-dialog, .modal-fill-in .modal.fade .modal-dialog {
  transform: scale(.5);
}

.modal-fill-in.show .modal-dialog, .modal-fill-in .modal.show .modal-dialog {
  transform: scale(1);
}

.breadcrumb-item, .breadcrumb-item a {
  color: #a3a4a6;
}

.breadcrumb-item:hover, .breadcrumb-item a:hover {
  color: #4e5155;
}

.list-group-item-secondary {
  color: #616263;
  background-color: #f3f5f7;
  border-color: #00000012;
}

a.list-group-item-secondary, button.list-group-item-secondary {
  color: #616263;
}

a.list-group-item-secondary:hover, a.list-group-item-secondary:focus, button.list-group-item-secondary:hover, button.list-group-item-secondary:focus {
  color: #616263;
  background-color: #f1f3f5;
}

a.list-group-item-secondary.active, button.list-group-item-secondary.active {
  color: #616263;
  background-color: #8897aa;
  border-color: #8897aa;
}

.list-group-item-success {
  color: #5c6360;
  background-color: #e6f8f1;
  border-color: #00000012;
}

a.list-group-item-success, button.list-group-item-success {
  color: #5c6360;
}

a.list-group-item-success:hover, a.list-group-item-success:focus, button.list-group-item-success:hover, button.list-group-item-success:focus {
  color: #5c6360;
  background-color: #e4f6ef;
}

a.list-group-item-success.active, button.list-group-item-success.active {
  color: #5c6360;
  background-color: #02bc77;
  border-color: #02bc77;
}

.list-group-item-info {
  color: #5e6464;
  background-color: #eaf9fb;
  border-color: #00000012;
}

a.list-group-item-info, button.list-group-item-info {
  color: #5e6464;
}

a.list-group-item-info:hover, a.list-group-item-info:focus, button.list-group-item-info:hover, button.list-group-item-info:focus {
  color: #5e6464;
  background-color: #e8f7f8;
}

a.list-group-item-info.active, button.list-group-item-info.active {
  color: #5e6464;
  background-color: #28c3d7;
  border-color: #28c3d7;
}

.list-group-item-warning {
  color: #66645f;
  background-color: #fffbee;
  border-color: #00000012;
}

a.list-group-item-warning, button.list-group-item-warning {
  color: #66645f;
}

a.list-group-item-warning:hover, a.list-group-item-warning:focus, button.list-group-item-warning:hover, button.list-group-item-warning:focus {
  color: #66645f;
  background-color: #fcf8ec;
}

a.list-group-item-warning.active, button.list-group-item-warning.active {
  color: #66645f;
  background-color: #ffd950;
  border-color: #ffd950;
}

.list-group-item-danger {
  color: #645f5f;
  background-color: #fbeeed;
  border-color: #00000012;
}

a.list-group-item-danger, button.list-group-item-danger {
  color: #645f5f;
}

a.list-group-item-danger:hover, a.list-group-item-danger:focus, button.list-group-item-danger:hover, button.list-group-item-danger:focus {
  color: #645f5f;
  background-color: #f8eceb;
}

a.list-group-item-danger.active, button.list-group-item-danger.active {
  color: #645f5f;
  background-color: #d9534f;
  border-color: #d9534f;
}

.list-group-item-dark {
  color: #5d5d5d;
  background-color: #e8e8e9;
  border-color: #00000012;
}

a.list-group-item-dark, button.list-group-item-dark {
  color: #5d5d5d;
}

a.list-group-item-dark:hover, a.list-group-item-dark:focus, button.list-group-item-dark:hover, button.list-group-item-dark:focus {
  color: #5d5d5d;
  background-color: #e6e6e7;
}

a.list-group-item-dark.active, button.list-group-item-dark.active {
  color: #5d5d5d;
  background-color: #181c21e6;
  border-color: #181c21e6;
}

.navbar {
  z-index: 2;
}

.fixed-top {
  z-index: 1030;
}

.navbar.navbar-dark {
  color: #fffc;
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: #fff6 !important;
}

.navbar.navbar-light {
  color: #181c2166;
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: #181c2133 !important;
}

.navbar-collapse, .navbar-brand, .navbar-text {
  flex-shrink: 1;
}

.navbar-dark hr {
  border-color: #ffffff1a;
}

.navbar-light hr {
  border-color: #181c210f;
}

.navbar-icon {
  font-size: 130%;
}

.navbar-search-box {
  align-items: center;
  margin: 0;
  display: flex;
}

.navbar-search-box:not(.active) {
  cursor: pointer;
}

.navbar-search-input {
  max-width: 0;
  align-items: center;
  transition: max-width .3s ease-in-out;
  display: flex;
  overflow: hidden;
}

.navbar-search-box.active .navbar-search-input {
  max-width: 100vw;
}

.navbar-search-input .form-control {
  width: auto;
  background: none;
  color: inherit !important;
  border: 0 !important;
  border-radius: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.navbar-dark .navbar-search-input .form-control::-webkit-input-placeholder {
  color: #fff6;
}

.navbar-dark .navbar-search-input .form-control:-ms-input-placeholder {
  color: #fff6;
}

.navbar-dark .navbar-search-input .form-control::placeholder {
  color: #fff6;
}

.navbar-light .navbar-search-input .form-control::-webkit-input-placeholder {
  color: #181c2133;
}

.navbar-light .navbar-search-input .form-control:-ms-input-placeholder {
  color: #181c2133;
}

.navbar-light .navbar-search-input .form-control::placeholder {
  color: #181c2133;
}

.navbar-search-cancel {
  opacity: .5;
  font-size: 1.55em;
  font-weight: 100;
  line-height: 1;
  color: inherit !important;
}

.navbar-search-cancel:hover, .navbar-search-cancel:focus {
  opacity: 1;
}

.navbar-expand-sm .navbar-search-box.nav-link {
  padding-top: 0;
  padding-bottom: 0;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm .navbar-search-input {
    max-width: 100vw !important;
    width: 100% !important;
  }

  .navbar-expand-sm .navbar-search-input .form-control {
    width: 100% !important;
  }

  .navbar-expand-sm .navbar-search-cancel {
    display: none !important;
  }

  .navbar-expand-sm.navbar-dark .navbar-search-box {
    color: #fff !important;
  }

  .navbar-expand-sm.navbar-light .navbar-search-box {
    color: #4e5155 !important;
  }
}

@media (min-width: 576px) {
  .navbar-expand-md .navbar-search-box.nav-link {
    padding-top: 0;
    padding-bottom: 0;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md .navbar-search-input {
    max-width: 100vw !important;
    width: 100% !important;
  }

  .navbar-expand-md .navbar-search-input .form-control {
    width: 100% !important;
  }

  .navbar-expand-md .navbar-search-cancel {
    display: none !important;
  }

  .navbar-expand-md.navbar-dark .navbar-search-box {
    color: #fff !important;
  }

  .navbar-expand-md.navbar-light .navbar-search-box {
    color: #4e5155 !important;
  }
}

@media (min-width: 768px) {
  .navbar-expand-lg .navbar-search-box.nav-link {
    padding-top: 0;
    padding-bottom: 0;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg .navbar-search-input {
    max-width: 100vw !important;
    width: 100% !important;
  }

  .navbar-expand-lg .navbar-search-input .form-control {
    width: 100% !important;
  }

  .navbar-expand-lg .navbar-search-cancel {
    display: none !important;
  }

  .navbar-expand-lg.navbar-dark .navbar-search-box {
    color: #fff !important;
  }

  .navbar-expand-lg.navbar-light .navbar-search-box {
    color: #4e5155 !important;
  }
}

@media (min-width: 992px) {
  .navbar-expand-xl .navbar-search-box.nav-link {
    padding-top: 0;
    padding-bottom: 0;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl .navbar-search-input {
    max-width: 100vw !important;
    width: 100% !important;
  }

  .navbar-expand-xl .navbar-search-input .form-control {
    width: 100% !important;
  }

  .navbar-expand-xl .navbar-search-cancel {
    display: none !important;
  }

  .navbar-expand-xl.navbar-dark .navbar-search-box {
    color: #fff !important;
  }

  .navbar-expand-xl.navbar-light .navbar-search-box {
    color: #4e5155 !important;
  }
}

@media (min-width: 1200px) {
  .navbar-expand .navbar-search-box.nav-link {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.navbar-expand .navbar-search-input {
  max-width: 100vw !important;
  width: 100% !important;
}

.navbar-expand .navbar-search-input .form-control {
  width: 100% !important;
}

.navbar-expand .navbar-search-cancel {
  display: none !important;
}

.navbar-expand.navbar-dark .navbar-search-box {
  color: #fff !important;
}

.navbar-expand.navbar-light .navbar-search-box {
  color: #4e5155 !important;
}

.mega-dropdown .dropdown-menu {
  width: 100%;
}

.mega-dropdown .dropdown-toggle {
  outline: 0;
}

.card {
  background-clip: padding-box;
  box-shadow: 0 1px 4px #181c2102;
}

.card-link {
  display: inline-block;
}

.card-header {
  line-height: 1.54;
}

.card-header, .card-footer, .card hr {
  border-color: #181c210f;
}

.card .list-group-item {
  border-color: #f1f1f2;
}

.card .row-bordered > [class^="col-"]:before, .card .row-bordered > [class^="col-"]:after, .card .row-bordered > [class*=" col-"]:before, .card .row-bordered > [class*=" col-"]:after, .card .row-bordered > [class^="col "]:before, .card .row-bordered > [class^="col "]:after, .card .row-bordered > [class*=" col "]:before, .card .row-bordered > [class*=" col "]:after, .card .row-bordered > [class$=" col"]:before, .card .row-bordered > [class$=" col"]:after, .card .row-bordered > [class="col"]:before, .card .row-bordered > [class="col"]:after {
  border-color: #181c210f;
}

.card-header.with-elements, .card-title.with-elements {
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  display: flex;
}

.card-header.with-elements {
  padding-top: .4375rem;
  padding-bottom: .4375rem;
}

.card-header-elements, .card-title-elements {
  flex-wrap: wrap;
  align-items: center;
  display: flex;
}

.card-header-elements + .card-header-elements, .card-header-elements > * + *, .card-title-elements + .card-header-elements, .card-title-elements > * + *, .card-header-elements + .card-title-elements, .card-title-elements + .card-title-elements {
  margin-left: .25rem;
}

.card-header-title {
  padding: .4375rem 0;
}

.card-header-elements {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.card > .list-group .list-group-item {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.card-condenced .card-header, .card-condenced .card-footer, .card-condenced > .list-group .list-group-item {
  padding-left: 1rem;
  padding-right: 1rem;
}

.card-condenced .card-body, .card-condenced .card-img-overlay {
  padding: 1rem;
}

html:not([dir="rtl"]) .card-hover[class], html[dir="rtl"] .card-hover[class] {
  transition: all .2s;
}

html:not([dir="rtl"]) .card-hover[class]:not(:hover), html[dir="rtl"] .card-hover[class]:not(:hover) {
  color: #4e5155 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  background: none !important;
  border-color: #0000 !important;
}

.ngb-accordion-with-icon .card-header > a {
  justify-content: space-between;
  display: flex;
}

.collapse-icon {
  width: .875rem;
  height: .875rem;
  align-self: center;
  display: block;
  position: relative;
}

.collapse-icon:after {
  content: "";
  width: .5rem;
  height: .5rem;
  border-bottom: .0625rem solid #181c214d;
  border-left: .0625rem solid #181c214d;
  margin-top: -.25rem;
  margin-left: -.25rem;
  transition: all .3s;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: rotate(-45deg);
}

[aria-expanded="true"] .collapse-icon:after {
  transform: rotate(135deg);
}

.footer-link {
  display: inline-block;
}

.footer-dark, .footer-dark .footer-link {
  color: #fffc;
}

.footer-dark .footer-link:hover, .footer-dark .footer-link:focus {
  color: #fff;
}

.footer-dark .footer-link.disabled {
  color: #fff6 !important;
}

.footer-dark .footer-text, .footer-dark .show > .footer-link, .footer-dark .active > .footer-link, .footer-dark .footer-link.show, .footer-dark .footer-link.active {
  color: #fff;
}

.footer-dark hr {
  border-color: #fff3;
}

.footer-light, .footer-light .footer-link {
  color: #181c2166;
}

.footer-light .footer-link:hover, .footer-light .footer-link:focus {
  color: #4e5155;
}

.footer-light .footer-link.disabled {
  color: #181c2133 !important;
}

.footer-light .footer-text, .footer-light .show > .footer-link, .footer-light .active > .footer-link, .footer-light .footer-link.show, .footer-light .footer-link.active {
  color: #4e5155;
}

.footer-light hr {
  border-color: #0000000f;
}

.sidenav {
  display: flex;
}

.sidenav .ps__thumb-y, .sidenav .ps__rail-y {
  width: .125rem !important;
}

.sidenav .ps__rail-y {
  background: none !important;
  left: auto !important;
  right: .25rem !important;
}

.sidenav .ps__rail-y:hover, .sidenav .ps__rail-y:focus, .sidenav .ps__rail-y.ps--clicking, .sidenav .ps__rail-y:hover > .ps__thumb-y, .sidenav .ps__rail-y:focus > .ps__thumb-y, .sidenav .ps__rail-y.ps--clicking > .ps__thumb-y {
  width: .375rem !important;
}

.sidenav-inner {
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0;
  padding: 0;
  display: flex;
}

.sidenav-item, .sidenav-header, .sidenav-divider, .sidenav-block {
  flex-direction: column;
  flex: none;
  margin: 0;
  padding: 0;
  list-style: none;
}

.sidenav-item {
  justify-content: flex-start;
  align-items: flex-start;
}

.sidenav-item.sidenav-item-animating {
  transition: height .2s ease-in-out;
}

.sidenav-item .sidenav-link {
  flex: 0 auto;
  align-items: center;
  display: flex;
  position: relative;
}

.sidenav-item.active > .sidenav-item .sidenav-link {
  font-weight: 500;
}

.sidenav-item.disabled .sidenav-item .sidenav-link {
  cursor: default !important;
}

.sidenav:not(.sidenav-no-animation) .sidenav-item .sidenav-link {
  transition-property: color, background-color;
  transition-duration: .2s;
}

.sidenav-item .sidenav-link > :not(.sidenav-icon) {
  flex: 0 auto;
}

.sidenav-toggle:after {
  content: "";
  width: .42em;
  height: .42em;
  border-top: 0;
  border-bottom: 1px solid;
  border-left: 1px solid;
  border-right: 0;
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%)rotate(45deg);
}

.sidenav-item.open:not(.sidenav-item-closing) > .sidenav-toggle:after {
  transform: translateY(-50%)rotate(-45deg);
}

.sidenav:not(.sidenav-no-animation) .sidenav-toggle:after {
  transition-property: -webkit-transform, transform;
  transition-duration: .2s;
}

.sidenav-menu {
  flex-direction: column;
  margin: 0;
  padding: 0;
  display: none;
}

.sidenav:not(.sidenav-no-animation) .sidenav-menu {
  transition: background-color .2s;
}

.sidenav-item.open > .sidenav-menu {
  display: flex;
}

.sidenav-icon {
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: .25rem;
  font-size: 1rem;
}

.sidenav-divider {
  width: 100%;
  border: 0;
  border-top: 1px solid;
}

.sidenav-vertical {
  flex-direction: column;
  overflow: hidden;
}

.sidenav-vertical:not(.sidenav-no-animation) {
  transition: width .2s;
}

.sidenav-vertical, .sidenav-vertical .sidenav-block, .sidenav-vertical .sidenav-inner > .sidenav-item, .sidenav-vertical .sidenav-inner > .sidenav-header {
  width: 15.625rem;
}

.sidenav-vertical .sidenav-inner {
  flex-direction: column;
  flex: auto;
}

.sidenav-vertical .sidenav-inner > .sidenav-item {
  margin: .0625rem 0;
}

.sidenav-vertical .sidenav-item .sidenav-link, .sidenav-vertical .sidenav-header, .sidenav-vertical .sidenav-block {
  padding: .75rem 2rem;
}

.sidenav-vertical .sidenav-divider {
  margin-top: .75rem;
  margin-bottom: .75rem;
  padding: 0;
}

.sidenav-vertical .sidenav-item .sidenav-toggle {
  padding-right: calc(2rem + 1.26em);
}

.sidenav-vertical .sidenav-item .sidenav-toggle:after {
  right: 2rem;
}

.sidenav-vertical .sidenav-menu, .sidenav-vertical .sidenav-menu .sidenav-link {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.sidenav-vertical .sidenav-icon {
  width: 1.75rem;
}

.sidenav-vertical .sidenav-menu .sidenav-icon {
  margin-right: 0;
}

.sidenav-vertical .sidenav-menu .sidenav-link {
  padding-left: 4rem;
}

.sidenav-vertical .sidenav-menu .sidenav-menu .sidenav-link {
  padding-left: 5rem;
}

.sidenav-vertical .sidenav-menu .sidenav-menu .sidenav-menu .sidenav-link {
  padding-left: 6rem;
}

.sidenav-vertical .sidenav-menu .sidenav-menu .sidenav-menu .sidenav-menu .sidenav-link {
  padding-left: 7rem;
}

.sidenav-vertical .sidenav-menu .sidenav-menu .sidenav-menu .sidenav-menu .sidenav-menu .sidenav-link {
  padding-left: 8rem;
}

.sidenav-horizontal {
  width: 100%;
  flex-direction: row;
}

.sidenav-horizontal .sidenav-inner {
  flex-direction: row;
  flex: 0 100%;
  overflow: hidden;
}

.sidenav-horizontal .sidenav-item .sidenav-link {
  padding: 1rem;
}

.sidenav-horizontal .sidenav-item .sidenav-toggle {
  padding-right: calc(1rem + 1.26em);
}

.sidenav-horizontal .sidenav-item .sidenav-toggle:after {
  right: 1rem;
}

.sidenav-horizontal .sidenav-inner > .sidenav-item > .sidenav-toggle:after {
  transform: translateY(-50%)rotate(-45deg);
}

.sidenav-horizontal .sidenav-inner > .sidenav-item:not(.sidenav-item-closing).open > .sidenav-toggle:after {
  transform: translateY(-50%)rotate(135deg);
}

.sidenav-horizontal .sidenav-header, .sidenav-horizontal .sidenav-divider {
  display: none !important;
}

.sidenav-horizontal .sidenav-menu {
  width: 12.5rem;
  position: absolute;
}

.sidenav-horizontal .sidenav-menu .sidenav-menu {
  width: auto;
  position: static;
}

.sidenav-horizontal .sidenav-menu .sidenav-link {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.sidenav-horizontal .sidenav-inner > .sidenav-item > .sidenav-menu {
  border-bottom-left-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.sidenav-horizontal:not(.sidenav-no-animation) .sidenav-inner > .sidenav-item.open > .sidenav-menu {
  animation: sidenavDropdownShow .2s ease-in-out;
}

.sidenav-horizontal .sidenav-menu .sidenav-menu .sidenav-link {
  padding-left: 2rem;
}

.sidenav-horizontal .sidenav-menu .sidenav-menu .sidenav-menu .sidenav-link {
  padding-left: 3rem;
}

.sidenav-horizontal .sidenav-menu .sidenav-menu .sidenav-menu .sidenav-menu .sidenav-link {
  padding-left: 4rem;
}

.sidenav-horizontal .sidenav-menu .sidenav-menu .sidenav-menu .sidenav-menu .sidenav-menu .sidenav-link {
  padding-left: 5rem;
}

.sidenav-horizontal-wrapper {
  width: 0;
  flex: 0 100%;
  overflow: hidden;
}

.sidenav:not(.sidenav-no-animation) .sidenav-horizontal-wrapper .sidenav-inner {
  transition: margin .2s;
}

.sidenav-horizontal-prev, .sidenav-horizontal-next {
  width: 2.25rem;
  flex: none;
  display: block;
  position: relative;
}

.sidenav-horizontal-prev:after, .sidenav-horizontal-next:after {
  content: "";
  width: .5rem;
  height: .5rem;
  border: 1px solid;
  border-top: 0;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
}

.sidenav-horizontal-prev.disabled, .sidenav-horizontal-next.disabled {
  cursor: default !important;
}

.sidenav-horizontal-prev:after {
  border-right: 0;
  transform: translate(-50%, -50%)rotate(45deg);
}

.sidenav-horizontal-next:after {
  border-left: 0;
  transform: translate(-50%, -50%)rotate(-45deg);
}

@keyframes sidenavDropdownShow {
  0% {
    opacity: 0;
    transform: translateY(-.5rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.sidenav-dark, .sidenav-dark .sidenav-link, .sidenav-dark .sidenav-horizontal-prev, .sidenav-dark .sidenav-horizontal-next {
  color: #fffc;
}

.sidenav-dark .sidenav-link:hover, .sidenav-dark .sidenav-link:focus, .sidenav-dark .sidenav-horizontal-prev:hover, .sidenav-dark .sidenav-horizontal-prev:focus, .sidenav-dark .sidenav-horizontal-next:hover, .sidenav-dark .sidenav-horizontal-next:focus, .sidenav-dark .sidenav-link.active, .sidenav-dark .sidenav-horizontal-prev.active, .sidenav-dark .sidenav-horizontal-next.active {
  color: #fff;
}

.sidenav-dark .sidenav-item.disabled .sidenav-link {
  color: #fff6 !important;
}

.sidenav-dark .sidenav-item.open:not(.sidenav-item-closing) > .sidenav-toggle, .sidenav-dark .sidenav-item.active > .sidenav-link {
  color: #fff;
}

.sidenav-dark .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
  background: #0000000f;
}

.sidenav-dark .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-menu, .sidenav-dark .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-toggle {
  color: #fffc;
}

.sidenav-dark .sidenav-text {
  color: #fff;
}

.sidenav-dark .sidenav-header {
  color: #fffc;
}

.sidenav-dark hr, .sidenav-dark .sidenav-divider, .sidenav-dark .sidenav-inner > .sidenav-item.open > .sidenav-menu:before {
  border-color: #fff3 !important;
}

.sidenav-dark .sidenav-inner > .sidenav-header:before, .sidenav-dark .sidenav-block:before {
  background-color: #fff6;
}

.sidenav-dark .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle:before {
  background-color: #fff3;
}

.sidenav-dark .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link:before {
  background-color: #fff;
}

.sidenav-dark .ps__thumb-y {
  background: #fffc !important;
}

.sidenav-light, .sidenav-light .sidenav-link, .sidenav-light .sidenav-horizontal-prev, .sidenav-light .sidenav-horizontal-next {
  color: #181c2166;
}

.sidenav-light .sidenav-link:hover, .sidenav-light .sidenav-link:focus, .sidenav-light .sidenav-horizontal-prev:hover, .sidenav-light .sidenav-horizontal-prev:focus, .sidenav-light .sidenav-horizontal-next:hover, .sidenav-light .sidenav-horizontal-next:focus, .sidenav-light .sidenav-link.active, .sidenav-light .sidenav-horizontal-prev.active, .sidenav-light .sidenav-horizontal-next.active {
  color: #4e5155;
}

.sidenav-light .sidenav-item.disabled .sidenav-link {
  color: #181c2133 !important;
}

.sidenav-light .sidenav-item.open:not(.sidenav-item-closing) > .sidenav-toggle, .sidenav-light .sidenav-item.active > .sidenav-link {
  color: #4e5155;
}

.sidenav-light .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
  background: #0000000d;
}

.sidenav-light .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-menu, .sidenav-light .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-toggle {
  color: #181c2166;
}

.sidenav-light .sidenav-text {
  color: #4e5155;
}

.sidenav-light .sidenav-header {
  color: #181c2166;
}

.sidenav-light hr, .sidenav-light .sidenav-divider, .sidenav-light .sidenav-inner > .sidenav-item.open > .sidenav-menu:before {
  border-color: #0000000f !important;
}

.sidenav-light .sidenav-inner > .sidenav-header:before, .sidenav-light .sidenav-block:before {
  background-color: #181c2133;
}

.sidenav-light .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle:before {
  background-color: #0000000f;
}

.sidenav-light .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link:before {
  background-color: #4e5155;
}

.sidenav-light .ps__thumb-y {
  background: #181c2166 !important;
}

.sidenav-collapsed:not(:hover) {
  width: 4.375rem;
}

.sidenav-collapsed:not(:hover) .sidenav-inner > .sidenav-item {
  width: 16.25rem;
}

.sidenav-collapsed:not(:hover) .sidenav-inner > .sidenav-item > .sidenav-link {
  padding-left: 4.375rem;
}

.sidenav-collapsed:not(:hover) .sidenav-inner > .sidenav-header, .sidenav-collapsed:not(:hover) .sidenav-block {
  width: 15.625rem;
  margin-left: 4.375rem;
  padding-left: .25rem;
  padding-right: 3.75rem;
  position: relative;
}

.sidenav-collapsed:not(:hover) .sidenav-inner > .sidenav-header:before, .sidenav-collapsed:not(:hover) .sidenav-block:before {
  content: "";
  width: 2.1875rem;
  display: block;
  position: absolute;
  top: .75rem;
  bottom: .75rem;
  left: -3.28125rem;
}

.sidenav-collapsed:not(:hover) .sidenav-inner > .sidenav-item > .sidenav-menu, .sidenav-collapsed:not(:hover) .sidenav-inner > .sidenav-item.open > .sidenav-menu {
  margin-left: .625rem;
  position: relative;
  background: none !important;
}

.sidenav-collapsed:not(:hover) .sidenav-inner > .sidenav-item > .sidenav-menu .sidenav-link, .sidenav-collapsed:not(:hover) .sidenav-inner > .sidenav-item.open > .sidenav-menu .sidenav-link {
  background: none !important;
  transition: none !important;
}

.sidenav-collapsed:not(:hover) .sidenav-inner > .sidenav-item.open > .sidenav-menu:before {
  content: "";
  width: 0;
  border-left: 2px solid;
  margin-left: -.625rem;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: calc(2.1875rem - 1px);
}

.sidenav-collapsed:not(:hover) .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle:before, .sidenav-collapsed:not(:hover) .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link:before {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-top: -4px;
  margin-left: calc(1.5625rem - 4px);
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
}

.sidenav-collapsed:not(:hover) .sidenav-inner > .sidenav-item > .sidenav-toggle:after {
  left: 3.5rem;
  right: auto;
}

.sidenav-collapsed:not(:hover) .sidenav-inner > .sidenav-item > .sidenav-link .sidenav-icon {
  width: 4.375rem;
  text-align: center;
  margin-left: -4.375rem;
}

.layout-wrapper, .layout-inner {
  width: 100%;
  flex: auto;
  align-items: stretch;
  display: flex;
}

.layout-wrapper {
  overflow: hidden;
}

.layout-inner {
  min-height: 100vh;
}

.layout-container, .layout-content, .layout-content > *, .layout-sidenav {
  min-height: 1px;
}

.layout-container {
  flex: auto;
  align-items: stretch;
  padding: 0;
  display: flex;
}

.layout-without-sidenav .layout-container {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.layout-content {
  flex-direction: column;
  flex: auto;
  justify-content: space-between;
  align-items: stretch;
  display: flex;
}

.layout-navbar, .layout-footer {
  flex: none;
}

.layout-navbar {
  z-index: 2;
  position: relative;
}

.layout-navbar .navbar {
  transform: translate3d(0, 0, 0);
}

.layout-sidenav {
  flex: 1 0 auto;
  position: relative;
}

.layout-sidenav .sidenav {
  transform: translate3d(0, 0, 0);
}

.layout-sidenav .sidenav-vertical {
  height: 100%;
}

.layout-1 .layout-inner {
  flex-direction: column;
}

.layout-1 .layout-content {
  width: 0;
  min-width: 0;
  max-width: 100%;
  flex-basis: 100%;
}

.layout-2 .layout-container {
  width: 0;
  min-width: 0;
  max-width: 100%;
  flex-direction: column;
  flex-basis: 100%;
}

.layout-2 .layout-content {
  width: 100%;
}

.layout-reversed .layout-1 .layout-container, .layout-reversed .layout-2 .layout-inner {
  flex-direction: row-reverse;
}

.layout-sidenav-toggle {
  display: block;
}

@media (max-width: 991px) {
  .layout-sidenav {
    will-change: transform, -webkit-transform;
    transform: translate3d(-100%, 0, 0);
    height: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
  }

  .layout-reversed .layout-sidenav {
    transform: translate3d(100%, 0, 0);
    left: auto !important;
    right: 0 !important;
  }

  .layout-expanded .layout-sidenav {
    transform: translate3d(0, 0, 0) !important;
  }

  .layout-expanded body {
    overflow: hidden;
  }

  .layout-overlay {
    opacity: .5;
    cursor: pointer;
    background: #181c21;
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100% !important;
  }

  .layout-expanded .layout-overlay {
    display: block;
  }

  .layout-sidenav-100vh .layout-sidenav, .layout-sidenav-100vh .layout-overlay {
    height: 100vh !important;
  }
}

@media (min-width: 992px) {
  .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav:not(:hover) .sidenav-vertical, .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav.sidenav-vertical:not(:hover) {
    width: 4.375rem;
  }

  .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav:not(:hover) .sidenav-vertical .sidenav-inner > .sidenav-item, .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav.sidenav-vertical:not(:hover) .sidenav-inner > .sidenav-item {
    width: 16.25rem;
  }

  .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav:not(:hover) .sidenav-vertical .sidenav-inner > .sidenav-item > .sidenav-link, .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav.sidenav-vertical:not(:hover) .sidenav-inner > .sidenav-item > .sidenav-link {
    padding-left: 4.375rem;
  }

  .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav:not(:hover) .sidenav-vertical .sidenav-inner > .sidenav-header, .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav:not(:hover) .sidenav-vertical .sidenav-block, .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav.sidenav-vertical:not(:hover) .sidenav-inner > .sidenav-header, .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav.sidenav-vertical:not(:hover) .sidenav-block {
    width: 15.625rem;
    margin-left: 4.375rem;
    padding-left: .25rem;
    padding-right: 3.75rem;
    position: relative;
  }

  .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav:not(:hover) .sidenav-vertical .sidenav-inner > .sidenav-header:before, .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav:not(:hover) .sidenav-vertical .sidenav-block:before, .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav.sidenav-vertical:not(:hover) .sidenav-inner > .sidenav-header:before, .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav.sidenav-vertical:not(:hover) .sidenav-block:before {
    content: "";
    width: 2.1875rem;
    display: block;
    position: absolute;
    top: .75rem;
    bottom: .75rem;
    left: -3.28125rem;
  }

  .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav:not(:hover) .sidenav-vertical .sidenav-inner > .sidenav-item > .sidenav-menu, .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav:not(:hover) .sidenav-vertical .sidenav-inner > .sidenav-item.open > .sidenav-menu, .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav.sidenav-vertical:not(:hover) .sidenav-inner > .sidenav-item > .sidenav-menu, .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav.sidenav-vertical:not(:hover) .sidenav-inner > .sidenav-item.open > .sidenav-menu {
    margin-left: .625rem;
    position: relative;
    background: none !important;
  }

  .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav:not(:hover) .sidenav-vertical .sidenav-inner > .sidenav-item > .sidenav-menu .sidenav-link, .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav:not(:hover) .sidenav-vertical .sidenav-inner > .sidenav-item.open > .sidenav-menu .sidenav-link, .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav.sidenav-vertical:not(:hover) .sidenav-inner > .sidenav-item > .sidenav-menu .sidenav-link, .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav.sidenav-vertical:not(:hover) .sidenav-inner > .sidenav-item.open > .sidenav-menu .sidenav-link {
    background: none !important;
    transition: none !important;
  }

  .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav:not(:hover) .sidenav-vertical .sidenav-inner > .sidenav-item.open > .sidenav-menu:before, .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav.sidenav-vertical:not(:hover) .sidenav-inner > .sidenav-item.open > .sidenav-menu:before {
    content: "";
    width: 0;
    border-left: 2px solid;
    margin-left: -.625rem;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: calc(2.1875rem - 1px);
  }

  .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav:not(:hover) .sidenav-vertical .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle:before, .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav:not(:hover) .sidenav-vertical .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link:before, .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav.sidenav-vertical:not(:hover) .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle:before, .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav.sidenav-vertical:not(:hover) .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link:before {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-top: -4px;
    margin-left: calc(1.5625rem - 4px);
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
  }

  .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav:not(:hover) .sidenav-vertical .sidenav-inner > .sidenav-item > .sidenav-toggle:after, .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav.sidenav-vertical:not(:hover) .sidenav-inner > .sidenav-item > .sidenav-toggle:after {
    left: 3.5rem;
    right: auto;
  }

  .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav:not(:hover) .sidenav-vertical .sidenav-inner > .sidenav-item > .sidenav-link .sidenav-icon, .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav.sidenav-vertical:not(:hover) .sidenav-inner > .sidenav-item > .sidenav-link .sidenav-icon {
    width: 4.375rem;
    text-align: center;
    margin-left: -4.375rem;
  }

  .layout-collapsed .layout-sidenav:hover {
    margin-right: -11.25rem;
  }

  .layout-collapsed.layout-reversed .layout-sidenav:hover {
    margin-left: -11.25rem;
    margin-right: 0;
  }

  .layout-collapsed.layout-offcanvas .layout-sidenav {
    margin-right: -15.625rem;
    transform: translateX(-100%);
  }

  .layout-collapsed.layout-offcanvas.layout-reversed .layout-sidenav {
    margin-left: -15.625rem;
    margin-right: 0;
    transform: translateX(100%);
  }

  .layout-fixed .layout-sidenav, .layout-fixed-offcanvas .layout-sidenav {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .layout-fixed.layout-reversed .layout-sidenav, .layout-fixed-offcanvas.layout-reversed .layout-sidenav {
    left: auto;
    right: 0;
  }

  .layout-fixed-offcanvas.layout-collapsed .layout-sidenav {
    transform: translateX(-100%);
  }

  .layout-fixed-offcanvas.layout-collapsed.layout-reversed .layout-sidenav {
    transform: translateX(100%);
  }

  .layout-fixed:not(.layout-collapsed) .layout-container, .layout-fixed-offcanvas:not(.layout-collapsed) .layout-container {
    padding-left: 15.625rem;
  }

  .layout-fixed:not(.layout-collapsed).layout-reversed .layout-container, .layout-fixed-offcanvas:not(.layout-collapsed).layout-reversed .layout-container {
    padding-left: 0;
    padding-right: 15.625rem;
  }

  .layout-fixed.layout-collapsed .layout-container {
    padding-left: 4.375rem;
  }

  .layout-fixed.layout-collapsed.layout-reversed .layout-container {
    padding-left: 0;
    padding-right: 4.375rem;
  }
}

html:not(.layout-navbar-fixed):not(.layout-fixed):not(.layout-fixed-offcanvas) .layout-container, html:not(.layout-navbar-fixed) .layout-2 .layout-container {
  padding-top: 0 !important;
}

html:not(.layout-footer-fixed) .layout-content {
  padding-bottom: 0 !important;
}

@media (max-width: 991px) {
  .layout-fixed .layout-wrapper.layout-1 .layout-sidenav, .layout-fixed-offcanvas .layout-wrapper.layout-1 .layout-sidenav {
    top: 0 !important;
  }

  html:not(.layout-navbar-fixed) .layout-1 .layout-container {
    padding-top: 0 !important;
  }
}

.layout-navbar-fixed .layout-navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

@media (min-width: 992px) {
  .layout-fixed .layout-1 .layout-navbar, .layout-fixed-offcanvas .layout-1 .layout-navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }

  .layout-navbar-fixed:not(.layout-collapsed) .layout-2 .layout-navbar, .layout-fixed.layout-navbar-fixed:not(.layout-collapsed) .layout-2 .layout-navbar, .layout-fixed-offcanvas.layout-navbar-fixed:not(.layout-collapsed) .layout-2 .layout-navbar {
    left: 15.625rem;
  }

  .layout-navbar-fixed:not(.layout-collapsed).layout-reversed .layout-2 .layout-navbar, .layout-fixed.layout-navbar-fixed:not(.layout-collapsed).layout-reversed .layout-2 .layout-navbar, .layout-fixed-offcanvas.layout-navbar-fixed:not(.layout-collapsed).layout-reversed .layout-2 .layout-navbar {
    left: 0;
    right: 15.625rem;
  }

  .layout-navbar-fixed.layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-2 .layout-navbar, .layout-fixed.layout-navbar-fixed.layout-collapsed .layout-2 .layout-navbar {
    left: 4.375rem;
  }

  .layout-navbar-fixed.layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas).layout-reversed .layout-2 .layout-navbar, .layout-fixed.layout-navbar-fixed.layout-collapsed.layout-reversed .layout-2 .layout-navbar {
    left: 0;
    right: 4.375rem;
  }
}

.layout-footer-fixed .layout-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

@media (min-width: 992px) {
  .layout-footer-fixed:not(.layout-collapsed) .layout-wrapper:not(.layout-without-sidenav) .layout-footer {
    left: 15.625rem;
  }

  .layout-footer-fixed:not(.layout-collapsed).layout-reversed .layout-wrapper:not(.layout-without-sidenav) .layout-footer {
    left: 0;
    right: 15.625rem;
  }

  .layout-footer-fixed.layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-wrapper:not(.layout-without-sidenav) .layout-footer {
    left: 4.375rem;
  }

  .layout-footer-fixed.layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas).layout-reversed .layout-wrapper:not(.layout-without-sidenav) .layout-footer {
    left: 0;
    right: 4.375rem;
  }
}

.layout-navbar-fixed body:not(.modal-open) .layout-1 .layout-navbar, .layout-fixed body:not(.modal-open) .layout-1 .layout-navbar, .layout-fixed-offcanvas body:not(.modal-open) .layout-1 .layout-navbar {
  z-index: 1080;
}

.layout-navbar-fixed body:not(.modal-open) .layout-2 .layout-navbar, .layout-fixed body:not(.modal-open) .layout-2 .layout-navbar, .layout-fixed-offcanvas body:not(.modal-open) .layout-2 .layout-navbar {
  z-index: 1075;
}

.layout-footer-fixed .layout-footer {
  z-index: 1030;
}

.layout-sidenav-horizontal {
  z-index: 9;
}

@media (max-width: 991px) {
  .layout-sidenav {
    z-index: 1100;
  }

  .layout-overlay {
    z-index: 1099;
  }
}

@media (min-width: 992px) {
  .layout-1 .layout-navbar {
    z-index: 10;
  }

  .layout-1 .layout-sidenav, .layout-2 .layout-navbar {
    z-index: 9;
  }

  .layout-2 .layout-sidenav {
    z-index: 10;
  }

  .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-1 .layout-sidenav:hover {
    z-index: 1075 !important;
  }

  .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-2 .layout-sidenav {
    z-index: 1085 !important;
  }

  .layout-fixed body:not(.modal-open) .layout-1 .layout-sidenav, .layout-fixed-offcanvas body:not(.modal-open) .layout-1 .layout-sidenav {
    z-index: 1075;
  }

  .layout-navbar-fixed body:not(.modal-open) .layout-2 .layout-sidenav, .layout-fixed body:not(.modal-open) .layout-2 .layout-sidenav, .layout-fixed-offcanvas body:not(.modal-open) .layout-2 .layout-sidenav {
    z-index: 1080;
  }
}

.layout-sidenav-link-no-transition .layout-sidenav .sidenav-link, .layout-sidenav-link-no-transition .layout-sidenav-horizontal .sidenav-link, .layout-no-transition .layout-sidenav, .layout-no-transition .layout-sidenav .sidenav, .layout-no-transition .layout-sidenav .sidenav-item, .layout-no-transition .layout-sidenav-horizontal, .layout-no-transition .layout-sidenav-horizontal .sidenav, .layout-no-transition .layout-sidenav-horizontal .sidenav-item {
  transition: none !important;
  animation: none !important;
}

@media (max-width: 991px) {
  .layout-transitioning .layout-overlay {
    animation: layoutSidenavAnimation .2s;
  }

  .layout-transitioning .layout-sidenav {
    transition-property: transform, -webkit-transform;
    transition-duration: .2s;
  }
}

@media (min-width: 992px) {
  .layout-collapsed:not(.layout-transitioning):not(.layout-offcanvas):not(.layout-fixed):not(.layout-fixed-offcanvas) .layout-sidenav {
    transition-property: margin-left, margin-right, width;
    transition-duration: .2s;
  }

  .layout-transitioning.layout-offcanvas .layout-sidenav {
    transition-property: margin-left, margin-right, transform, -webkit-transform;
    transition-duration: .2s;
  }

  .layout-transitioning.layout-fixed .layout-container, .layout-transitioning.layout-fixed-offcanvas .layout-container {
    transition-property: padding-left, padding-right;
    transition-duration: .2s;
  }

  .layout-transitioning.layout-fixed .layout-sidenav {
    transition: width .2s;
  }

  .layout-transitioning.layout-fixed-offcanvas .layout-sidenav {
    transition-property: transform, -webkit-transform;
    transition-duration: .2s;
  }

  .layout-transitioning.layout-navbar-fixed .layout-2 .layout-navbar, .layout-transitioning.layout-footer-fixed .layout-footer {
    transition-property: left, right;
    transition-duration: .2s;
  }

  .layout-transitioning:not(.layout-offcanvas):not(.layout-fixed):not(.layout-fixed-offcanvas) .layout-sidenav {
    transition-property: margin-left, margin-right, width;
    transition-duration: .2s;
  }
}

@media (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .sidenav, .layout-sidenav, .layout-container, .layout-navbar, .layout-footer {
    transition: none !important;
  }

  .layout-overlay {
    animation: none !important;
  }
}

@keyframes layoutSidenavAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: .5;
  }
}

.app-brand {
  min-height: 1px;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  line-height: 1;
  display: flex;
  overflow: hidden;
}

.app-brand-logo {
  min-height: 1px;
  flex-grow: 0;
  flex-shrink: 0;
  display: block;
  overflow: hidden;
}

.app-brand-logo img, .app-brand-logo svg {
  display: block;
}

.app-brand-text {
  flex-shrink: 0;
  display: block;
}

.app-brand .layout-sidenav-toggle {
  display: block;
}

.sidenav-vertical .app-brand {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.sidenav-horizontal .app-brand, .sidenav-horizontal .app-brand + .sidenav-divider {
  display: none !important;
}

:not(.layout-sidenav) > .sidenav-vertical.sidenav-collapsed:not(.layout-sidenav):not(:hover) .app-brand {
  width: 4.375rem;
  padding-left: 0;
  padding-right: 0;
}

:not(.layout-sidenav) > .sidenav-vertical.sidenav-collapsed:not(.layout-sidenav):not(:hover) .app-brand-logo, :not(.layout-sidenav) > .sidenav-vertical.sidenav-collapsed:not(.layout-sidenav):not(:hover) .app-brand-text {
  margin-left: auto;
  margin-right: auto;
}

:not(.layout-sidenav) > .sidenav-vertical.sidenav-collapsed:not(.layout-sidenav):not(:hover) .app-brand-logo ~ .app-brand-text, :not(.layout-sidenav) > .sidenav-vertical.sidenav-collapsed:not(.layout-sidenav):not(:hover) .app-brand .layout-sidenav-toggle {
  display: none;
}

@media (min-width: 992px) {
  .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav:not(:hover) .app-brand {
    width: 4.375rem;
    padding-left: 0;
    padding-right: 0;
  }

  .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav:not(:hover) .app-brand-logo, .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav:not(:hover) .app-brand-text {
    margin-left: auto;
    margin-right: auto;
  }

  .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav:not(:hover) .app-brand-logo ~ .app-brand-text, .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav:not(:hover) .app-brand .layout-sidenav-toggle {
    display: none;
  }
}

/*# sourceMappingURL=index.dd96aa0e.css.map */
